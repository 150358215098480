<template>
    <div class="page-content">
        <nav class="page-breadcrumb" id="breadcrumb">
            <ol class="breadcrumb">
            <li class="breadcrumb-item">{{breadcrumbA}}</li>
            <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
            </ol>
        </nav>
        <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                <h6 class="card-title">{{breadcrumbB}}</h6>
                <p class="card-description"> {{descripcion}}</p>
                </div>  
            </div>
            </div>
        </div> 



        <div class="row mb-3">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <label class="form-label">Seleccione un curso y un modo para elaborar el reporte: </label>
                        <form class="form-row" v-if="distribution && courses && capacitaciones">
                            <div class="col">
                                <select class="form-control" v-model="selectedCourse" @change="showReport()">
                                    <option value="null" disabled>Seleccione un curso</option>
                                    <option v-for="(course,c) in courses" :value="c" :key="c">
                                        {{course.title}}
                                    </option>
                                </select>
                            </div>
                            <div class="col">
                                <select class="form-control" v-model="selectedReport">
                                    <option value="null" disabled>Seleccione un tipo de reporte</option>
                                    <option value="reportZonal">
                                        Reporte por zonal
                                    </option>
                                    <option value="reportTotal">
                                        Reporte completo
                                    </option>
                                </select>
                            </div>
                            <div class="col">
                                <button class="btn btn-primary" style="height:35px" @click.prevent="showReport()" :disabled="selectedCourse==null || selectedReport == null">Generar reporte</button>
                            </div>
                        </form>
                        <div v-else class="load-box"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="trigger.reportZonal">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <TrainedByCourse :course="{...courses[selectedCourse],...{course_id:selectedCourse}}" :distribution="distribution" :trained="capacitaciones"/>
                    </div>
                </div>
            </div>
        </div>


        <div class="row" v-if="trigger.reportTotal">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <!-- <div class="alert alert-light">{{calculateReportData()}}</div> -->
                        <list 
                            :data="calculateReportData()"
                            title="Reporte del curso:"
                            :titleBadge="courses[selectedCourse].title"
                            :columns="[
                                {
                                    label: 'Leg',
                                    trackBy: 'legajo',
                                    type: 'badge'
                                },
                                {
                                    label: 'DNI',
                                    trackBy: 'dni',
                                    type: 'badge'
                                },
                                {
                                    label: 'Nombre',
                                    trackBy: 'nombre',
                                    type: 'text'
                                },
                                {
                                    label: 'Fecha',
                                    trackBy: 'last_update',
                                    type: 'badge'
                                },...calculateReportColumns()             
                            ]"
                            @show-exams="showExamDetail($event)"
                        >
                        </list>
                    </div>
                </div>
            </div>
        </div>
        <lista-carga v-if="trigger.loading"></lista-carga>


        <transition name="modal" v-if="trigger.showExam">
            <div class="modal-mask" style="cursor: default;">
            <div class="modal-wrapper">
                <div class="modal-container">
                <div class="modal-body">
                    <div name="body">
                    <div class="card">
                        <div class="card-body text-left">
                        <div v-if="employeeAttemp.last_attemp != undefined" id="printElement">
                            <div class="d-flex align-items-center justify-content-between mb-3">
                            <h5 class="mb-0">Examen: <div class="badge badge-primary">{{capitalizeEacheR(employeeAttemp.nombre)}}</div></h5>
                            <button class="btn btn-light" @click="showExamDetail(null)">&larr; Volver</button>
                            </div>

                            <div class="bg-light p-3 rounded mb-2 d-flex align-items-center justify-content-between">
                                <div>
                                    <h5 class="mb-1">{{capitalizeEacheR(employeeAttemp.module_name)}}</h5>
                                    <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                    &nbsp;
                                    <div class="badge bg-white">
                                        {{dateFormateR(employeeAttemp.last_attemp.timestamp)}}
                                    </div>
                                    &nbsp;
                                    Nota:
                                    <div class="badge bg-white">
                                        {{employeeAttemp.last_attemp.resultado}}
                                    </div>
                                </div>
                                <div>
                                    <div class="btn btn-xs btn-primary mr-auto" @click="getPDF(employeeAttemp.module_name,employeeAttemp.nombre)">
                                        <svg viewBox="0 0 384 512" width="14p" height="14" fill="currentColor" style="vertical-align: bottom;"><path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM216 232V334.1l31-31c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-72 72c-9.4 9.4-24.6 9.4-33.9 0l-72-72c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l31 31V232c0-13.3 10.7-24 24-24s24 10.7 24 24z"/></svg>
                                        PDF
                                    </div>
                                </div>
                            </div>

                            <table class="table d-none" id="tablee">
                                <tbody>
                                    <tr>
                                        <th>
                                            Nombre: {{capitalizeEacheR(employeeAttemp.nombre)}} |

                                            Capacitacion: {{capitalizeEacheR(employeeAttemp.module_name)}} <br><br>

                                            Fecha: {{dateFormateR(employeeAttemp.last_attemp.timestamp)}} |

                                            Nota: {{employeeAttemp.last_attemp.resultado}} |

                                            Resultado: {{(employeeAttemp.last_attemp.resultado >= 70) ? "APROBADO" : "DESAPROBADO"}}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td><br>Detalle del exámen<br></td>
                                    </tr>
                                    <tr v-for="exam,e,i in employeeAttemp.last_attemp.data" :key="e">
                                        <th scope="row">
                                            <br>
                                            {{i+1}}. {{exam.statement}}
                                            <br>
                                            <template v-for="answer,a in exam.answers" :key="a">
                                                <br>
                                                <b border="1">
                                                    ({{(answer.correct)?'Correcta':'Incorrecta'}}) {{answer.label}} {{(answer.checked)?'[Respuesta seleccionada]':''}}
                                                </b>
                                                <br>
                                            </template>
                                        </th>
                                        
                                    </tr>
                                </tbody>
                            </table>

                            <div id="data-content">
                                <div v-for="exam,e,i in employeeAttemp.last_attemp.data" :key="e">
                                    <div class="border border-light p-2" style="margin-top:-1px">
                                        <b>{{i+1}}. {{exam.statement}} </b>
                                        <div v-for="answer,a in exam.answers" :key="a">
                                            <b v-if="answer.checked">
                                            {{(answer.checked)?'&#x2611;':'&#9744;'}} {{answer.label}} ({{(answer.correct)?'Correcta':'Incorrecta'}})
                                            </b>
                                            <span v-else>
                                            {{(answer.checked)?'&#x2611;':'&#9744;'}} {{answer.label}} ({{(answer.correct)?'Correcta':'Incorrecta'}})
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>




                        </div>


                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </transition>


        <!-- <lista-carga v-if="!trigger.list"></lista-carga> -->
        <!-- Template content  -->
        <!-- <div class="card" v-else>
            <div class="card-body">
                <list 
                    :data="distribution"
                    :columns="[
                       {
                            label: 'Nombre',
                            trackBy: 'name',
                            type: 'text'
                       },
                       {
                            label: 'Dotacion',
                            trackBy: 'dotacion_count',
                            type: 'badge',
                            conditionColors:[
                                {color:'success',condition:'equal',amount:100},
                                {color:'danger',condition:'less',amount:100}
                            ]
                       },                  
                    ]"
                    :actions="[
                        {
                            label:'test',
                            emitter: 'tested',
                            color: 'warning'
                        }
                    ]"
                >
                </list>
            </div>
        </div> -->
    </div>
</template>
<script>
import axios from 'axios';
import {capitalizeEach, dateFormat, getDataFrom} from '@/utils'
import $ from 'jquery';
import firebase from "firebase/app";
import "firebase/database";
// import ModalAdministrar from './ModalAdministrar.vue';
// import ModalObservar from './ModalObservar.vue';
import ListaCarga from "../ListaCarga";
import List from '@/components/List'

import TrainedByCourse from './TrainedByCourse.vue'
// import ModalZonal from './ModalZonal.vue';

import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';

require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();

const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));

export default {
    components: {
        ListaCarga,
        List,
        TrainedByCourse,
    },    
    name: 'Trained',
    data() {
        return {
            breadcrumbA: 'Inicio',
            breadcrumbB: 'Capacitaciones',
            descripcion: 'En este modulo usted podra visualizar las capacitaciones por zonal.',
            tickets:null,
            filteredTickets:null,

            selectedCourse:null,
            selectedReport:null,

            distribution:null,
            courses:null,
            capacitaciones:null,

            reportTotal:null,
            employeeAttemp:null,

            selected:null,
            setPriority:null,
            selectedPriority:null,
            trigger:{list:false,loading:false,reportZonal:false,reportTotal:false,showExam:false},
        }
    },
    // computed:{
        
    // },
    methods: {
        getPDF(modulo,empleado){
            const doc = new jsPDF()
            autoTable(doc, { html: '#tablee' })
            let year = new Date().getFullYear();
            let m = modulo.toLocaleLowerCase().replaceAll(' ','_').replaceAll(".","");
            let e = empleado.toLocaleLowerCase().replaceAll(' ','_');
            doc.save(`${m}_${e}_${year}.pdf`)
        },
        showExamDetail(data){
            if(data == null || data == undefined){
                this.employeeAttemp = null;
                this.trigger.showExam = false;
                return;
            }

            this.employeeAttemp = {}
            this.employeeAttemp.nombre = data.data.nombre
            this.employeeAttemp.dni = data.data.dni
            this.employeeAttemp.legajo = data.data.legajo
            this.employeeAttemp.module_name = this.courses[this.selectedCourse].modules[data.id].title

            if(this.courses[this.selectedCourse].modules[data.id].exams != undefined){
                let exam_id = Object.keys(this.courses[this.selectedCourse].modules[data.id].exams).at(0); 
                let last_attemp_id = Object.keys(this._capacitacion[data.data.dni][exam_id].intentos).at(-1);
                this.employeeAttemp['last_attemp'] = this._capacitacion[data.data.dni][exam_id].intentos[last_attemp_id];
            }

            this.trigger.showExam = true;
        },
        isEditor(modulo){
          if (userPermission.find( record => record.ModulosAccess === modulo)) {
            var status =  userPermission.find( record => record.ModulosAccess === modulo).Editor;
            // console.log(modulo+' : '+status)
            return status;
          }else{
            // console.log(modulo+' : '+false)
            return false;
          }
        },
        dateFormat(initDate,includeYear){
            let date = new Date(initDate)

            let day = date.getDate()
            let month = date.getMonth() + 1
            let year;
            if (includeYear==undefined) year = date.getFullYear()

            if(month < 10){
                if(day<10){
                    return (includeYear!=undefined && !includeYear) ? (`0${day}-0${month}`) : (`0${day}-0${month}-${year}`)
                }
                return (includeYear!=undefined && !includeYear) ? (`${day}-0${month}`) : (`${day}-0${month}-${year}`)
            }else{
                if(day<10){
                    return (includeYear!=undefined && !includeYear) ? (`0${day}-${month}`) : (`0${day}-${month}-${year}`)
                }
                return (includeYear!=undefined && !includeYear) ? (`${day}-${month}`) : (`${day}-${month}-${year}`)
            }
        },
        getDistribution(){
            let current_date = Date.now();
            return new Promise((resolve)=>{
                let entities = {
                    legales:{ query: 'legales', api: 'aplicativos', data:null },
                    volantes:{ query: 'volantes', api: 'aplicativos', data:null },
                    distribucion:{ query: 'distribucion', api: 'aplicativos', data:null },
                    zonales:{ query: 'zonal', api: 'aplicativos', data:null },
                    personal:{ query: 'personal', api: 'rrhh', data:null },
                    personal_cat:{ query: 'personal_cat', api: 'rrhh', data:null },
                    operaciones:{
                        query: 'https://salida.foodservice.com.ar/api/consumos/operaciones',
                        data:null
                    },
                }
    
                // Se hacen todos los request de entidades
                let promises = Object.keys(entities).map((e)=>{
                    // Se guardan como promesas
                    return getDataFrom(entities[e].query,entities[e].api).then((data)=>entities[e].data=data)
                });

                // Se resuelven esas promsesas
                Promise.all(promises).then(()=>{
                    // Object.keys(entities).forEach((e)=>console.log(e,entities[e].data))
                    
                    let result = {}; // Se guarda aqui la distribucion
    
                    Object.keys(entities.zonales.data).forEach((o)=>{
                        // Si no fue agregado el zonal
                        if(result[entities.zonales.data[o].legajo] == undefined) {
                            // Se crea su dotacion
                            result[entities.zonales.data[o].legajo] = {
                                name: entities.zonales.data[o].nombre,
                                legajo: entities.zonales.data[o].legajo,
                                operations:{},
                                dotacion_count:0,
                            }
                        }
    
                        let operation = entities.operaciones.data.find((op)=> op.id == o);
    
                        if(operation!=undefined && entities.distribucion.data[o]!=undefined){
                            let distribution = {};

                            Object.keys(entities.distribucion.data[o]).forEach((di)=>{
                                if (entities.distribucion.data[o][di].legajo == entities.zonales.data[o].legajo) return
                                let e = this.getEmployeeFrom(
                                    entities.distribucion.data[o][di].legajo,
                                    [entities.personal.data, entities.personal_cat.data])

                                /**
                                 *  El legajo 155 pertenece a DIAMELA BELEN, le dieron de alta con otro legajo y está duplicada
                                 */ 
                                if (!e.leg_fecegr && e.leg_numero != 155) {
                                    // comprobar la fecha de ingreso menor a 15 dias
                                    let entry = new Date(e.leg_fecing.date);
                                    // console.log(current_date,entry.getTime());
                                    if((current_date - entry.getTime())/(1000*60*60*24)>15){
                                        distribution[di] = entities.distribucion.data[o][di]
                                    }
                                }
                            });

                            result[entities.zonales.data[o].legajo].operations[o] = {
                                operation_name: operation.name,
                                operation_id: operation.id,
                                personal: distribution,
                            }
                            if(entities.distribucion.data[o]!=undefined) {
                                result[entities.zonales.data[o].legajo].dotacion_count += Object.keys(distribution).length;
                            }
                        } else if(o==999991){

                            // console.log('entra en sector zonales',entities.distribucion.data[o]);
                            result[entities.zonales.data[o].legajo].operations[o] = {
                                operation_name: (o==999991) ? "SECTOR ZONALES" : "VOLANTES",
                                operation_id: o,
                                personal: entities.distribucion.data[o],
                            }
    
                            if(entities.distribucion.data[o]!=undefined) {
                                result[entities.zonales.data[o].legajo].dotacion_count += Object.keys(entities.distribucion.data[o]).length;
                            }
                        }
    
                    });

                    Object.keys(entities.volantes.data).forEach((responsable_dni)=>{

                        // Check if volante exists (No tiene fecha de egreso, no está en lagales)
                        Object.keys(entities.volantes.data[responsable_dni]).forEach((i)=>{

                            // Recorriendo volante i en la dotacion de zonal responsable
                            let volante = entities.volantes.data[responsable_dni][i]

                            // Se comprueba si está distribuido en operacion zonales "900101"
                            if(!Object.keys(entities.distribucion.data['900101']).some((k)=>entities.distribucion.data['900101'][k].legajo == volante.legajo)){
                                return
                            }

                            // find personal
                            let p = null
                            if(entities.personal.data != null) {
                                p = Object.keys(entities.personal.data).find((i)=>entities.personal.data[i].leg_numero==volante.legajo)
                                if (p != undefined && p!=null){
                                    p = entities.personal.data[p]
                                }
                            }

                            if(entities.personal_cat.data != null && (p==undefined || p==null)){
                                p = Object.keys(entities.personal_cat.data).find((i)=>entities.personal_cat.data[i].leg_numero==volante.legajo)
                                if (p != undefined && p!=null){
                                    p = entities.personal_cat.data[p]
                                }
                            }


                            if(p!=undefined && p!=null && p.leg_fecegr == undefined && entities.legales.data[p.leg_numdoc]==undefined){
                                // console.log('volante:',p.leg_nombre,p.leg_apellido,p);
                                // El empleado no fue dado de baja, se busca en legales
                                // console.log('volante valido',p.leg_nombre,'del zonal dni',responsable_dni);

                                // Obtener legajo del zonal
                                let zonal = null
                                if (entities.personal.data[responsable_dni] != undefined) {
                                    zonal = entities.personal.data[responsable_dni]

                                } else if(entities.personal_cat.data[responsable_dni] != undefined){
                                    zonal = entities.personal_cat.data[responsable_dni]
                                }
                                

                                if(zonal!=null && result[zonal.leg_numero]!=undefined){
                                    // if(zonal.leg_numero == 7654){
                                    //     console.log('el tipo',volante.nombre,' entra en', result[zonal.leg_numero]);
                                    // }
                                    
                                    // 900101 es el sector volantes
                                    if(result[zonal.leg_numero].operations['900101']==undefined){
                                        result[zonal.leg_numero].operations['900101'] = {
                                            'operation_name': "VOLANTES",
                                            'operation_id': 900101,
                                            'personal': {}
                                        }
                                    }

                                    result[zonal.leg_numero].operations['900101'].personal[i] = {
                                        legajo:volante.legajo,
                                        nombre:volante.nombre,
                                    }

                                    result[zonal.leg_numero].dotacion_count += 1
                                }
                            }

                        })


                        

                    })
                    // console.log(result);
                    resolve(result)
                })
            });






            // return new Promise((resolve)=>{
            //     // getDataFrom('legales','aplicativos').then((legales)=>{
            //     //     getDataFrom('volantes','aplicativos').then((volantes)=>{
            //     //         // console.log('legales',legales);
            //     //         // console.log('volantes',volantes);
                        
            //     //     })
            //     // })
            //     // Obtener distribucion
            //     axios.get('https://aplicativos.firebaseio.com/distribucion.json').then((response)=>{
            //         let distribucion = response.data;
            //         // console.log('raw distribution',response.data);

            //         // Zonal por operacion
            //         axios.get('https://aplicativos.firebaseio.com/zonal.json').then((response)=>{
            //             let zonales = response.data;

            //             // Operaciones
            //             axios.get('https://salida.foodservice.com.ar/api/consumos/operaciones').then((response)=>{
            //                 let operaciones = response.data;
                            
            //                 let result = {};

            //                 Object.keys(zonales).forEach((o)=>{
            //                     if(result[zonales[o].legajo] == undefined) {
            //                         result[zonales[o].legajo] = {
            //                             name: zonales[o].nombre,
            //                             legajo: zonales[o].legajo,
            //                             operations:{},
            //                             dotacion_count:0,
            //                         }
            //                     }

            //                     let operation = operaciones.find((op)=> op.id == o);

            //                     if(operation!=undefined){
            //                         result[zonales[o].legajo].operations[o] = {
            //                             operation_name: operation.name,
            //                             operation_id: operation.id,
            //                             personal: distribucion[o],
            //                         }
            //                         if(distribucion[o]!=undefined) {
            //                             result[zonales[o].legajo].dotacion_count += Object.keys(distribucion[o]).length;
            //                         }
            //                     } else if(o==999991 || o==900101){
            //                         // console.log('entra en sector zonales');
            //                         result[zonales[o].legajo].operations[o] = {
            //                             operation_name: (o==999991) ? "SECTOR ZONALES" : "VOLANTES",
            //                             operation_id: o,
            //                             personal: distribucion[o],
            //                         }

            //                         if(distribucion[o]!=undefined) {
            //                             result[zonales[o].legajo].dotacion_count += Object.keys(distribucion[o]).length;
            //                         }
            //                     }

            //                 });

            //                 Object.keys(volantes).forEach((v)=>{
                                
            //                 })

            //                 console.log('result',result);

            //                 resolve(result);
            //             })
                    
            //         })
            //     })
            // });
        },
        calculateReportColumns(){
            let modules = this.courses[this.selectedCourse].modules;
            let columns = Object.keys(modules).map((m,i)=>{
                let modulo = modules[m];

                return {
                    label: 'Mod-'+i,
                    trackBy: m,
                    type: 'actionBadge',
                    actionBadgeEmitter:'show-exams',
                    conditionColors:[
                        {color:'success',condition:'equal',amount:'Aprobado'},
                        {color:'danger',condition:'equal',amount:'Desaprobado'},
                        {color:'light',condition:'equal',amount:'Sin realizar'}
                    ]
                }
            });

            // console.log(columns);
            return columns

        },
        calculateReportData(){
            // recorro capacitados obtengo DNI, nombre, legajo y guardo en object a traves de clave (dni)
            //     Obtengo el curso actual
            //         recorro curso_actual.modules
            //             recorro examenes en curso_actual.modules.examenes   
            //                 total = 0; por cada exam -> total++; approved++
            //                     (approved = Object.keys(intentos).at(-1).score > 70)
            //                 si aproved==total
            //                     capacitado[dni][module_id] = true

            let data = {};

            // recorrer capacitados
            Object.keys(this._capacitacion).forEach((dni)=>{
                
                // Se recorren los modulos del curso (luego se guardaran en data por id)
                Object.keys(this.courses[this.selectedCourse].modules).forEach((m_key)=>{
                    let total_exams = 0;
                    let approved = 0;
                    let undone = false;
                    let exams = this.courses[this.selectedCourse].modules[m_key].exams;
                    let last_date_attemp = -1

                    if(exams!=undefined){
                        Object.keys(exams).forEach((e_key)=>{
                            // If the employee was not saved, We take advantage that we iterate through the information
                            if (this._capacitacion[dni][e_key] && data[dni]==undefined) {
                                data[dni]={
                                    nombre: this._capacitacion[dni][e_key].nombre,
                                    legajo: this._capacitacion[dni][e_key].legajo,
                                    dni: dni,
                                }

                                // console.log('un capacitadoooooo',this._capacitacion[dni][e_key]);
                            }
    
                            if(this._capacitacion[dni][e_key]!=undefined && this._capacitacion[dni][e_key].intentos!=undefined){
                                let last_attemp = Object.keys(this._capacitacion[dni][e_key].intentos).at(-1)
                                if(this._capacitacion[dni][e_key].intentos[last_attemp].resultado >= 70){
                                    approved++
                                    // console.log(last_date_attemp,'<',this._capacitacion[dni][e_key].intentos[last_attemp].timestamp);
                                    if(last_date_attemp<this._capacitacion[dni][e_key].intentos[last_attemp].timestamp){
                                        last_date_attemp = this._capacitacion[dni][e_key].intentos[last_attemp].timestamp
                                    }
                                } else {
                                    if(last_date_attemp<this._capacitacion[dni][e_key].intentos[last_attemp].timestamp){
                                        last_date_attemp = this._capacitacion[dni][e_key].intentos[last_attemp].timestamp
                                    }
                                }
                            } else {
                                undone = true
                            }
                            
                            total_exams++
                        });
                    }

                    // Si fue guardado quiere decir que tiene capacitaciones en este curso
                    if(data[dni]!=undefined){
                        // console.log(dni,m_key,total_exams, approved);
                        // Check if approved
                        if(total_exams == approved){
                            data[dni][m_key] = 'Aprobado'
                        } else if(undone){
                            data[dni][m_key] = 'Sin realizar'
                        }else {
                            data[dni][m_key] = 'Desaprobado'
                        }
                        // console.log('cargando un date',last_date_attemp);
                        data[dni]['last_update'] = this.dateFormateR(last_date_attemp)
                    }
                })
                


            })


        // console.log('calculate_report_data',data);
           return data

        },
        // getModulesAsColumns(){
        //     let modules = this.courses[this.selectedCourse].modules;

        //     let columns = Object.keys(modules).map((m,i)=>{
        //         let modulo = modules[m];

        //         return {
        //             label: 'Mod-'+i,
        //             trackBy: 'any',
        //             type: 'badge',
        //             conditionColors:[
        //                 {color:'success',condition:'equal',amount:100},
        //                 {color:'danger',condition:'less',amount:100}
        //             ]
        //         }
        //     });

        //     return columns;
        //     // Object.keys(personal)

        //     // Object.keys(temp0).forEach((o)=>{
        //     //     let personal = temp0[o].personal;
                
        //     //     if(personal != undefined){
        //     //         // Recorrer las capacitaciones
                    
        //     //         // Comprobar si todos los exámenes del modulo fueron aprobados
        //     //             // Se contraste con data de courses[curso_id].module[module_id].exams
                    
        //     //         // Si completo, suma
        //     //     }
        //     // })
        // },
        showReport(){
            if(this.selectedReport == null) return
            this.trigger.reportZonal = false;
            this.trigger.reportTotal = false;
            this.trigger.loading = true;

            setTimeout(() => {
                this.trigger.loading = false;
                this.trigger[this.selectedReport] = true;
            }, 500);
        },
        getEmployeeFrom(leg,datas){
            let employee = null

            datas.some((data)=>{
                return Object.keys(data).some((d)=>{
                    if(data[d].leg_numero == leg){
                        employee = data[d];
                        return true
                    }
                })
            });

            return employee
        },
        capitalizeEacheR(d){return capitalizeEach(d)},
        dateFormateR(d){return dateFormat(d)},
    },
    async mounted() {
        console.log('Estoy en trained by zonal');
        this.getDistribution().then((distribution)=>{
            // console.log('distribucion',JSON.parse(JSON.stringify(distribution)));
            

            this.distribution = {};
            
            let filtered = Object.keys(distribution).filter((a)=>Object.keys(distribution[a].operations).length && (a != "4294" && a!="3370" && a!="9493" && a!="8936" && a!="6944" ))
            filtered.forEach((l)=>this.distribution[l] = distribution[l]);

            axios.get('https://capacitacion-fs.firebaseio.com/courses.json').then((response)=>{
                this.courses = response.data;                

                axios.get('https://aplicativos.firebaseio.com/capacitacion.json').then((response)=>{
                    this.capacitaciones = {};
                    this._capacitacion = response.data;

                    Object.keys(response.data).forEach((dni)=>{
                        let legajo = response.data[dni][Object.keys(response.data[dni]).at(0)].legajo;
                        this.capacitaciones[legajo] = response.data[dni];
                    });
                });

                // console.log(this.getModulesAsColumns());

                this.trigger.list = true;

            });            
        })

    },
}

</script>

<style scoped>
/* .text-wrap {
    width: 300px;
    white-space: -moz-pre-wrap !important;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    white-space: pre-wrap;
    word-wrap: break-word;
    white-space: -webkit-pre-wrap;
    word-break: break-all;
    white-space: normal;
    hyphens: auto;

    background: #ececec;
    border-radius: 4px;
    padding: 0.4rem;
} */

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    width:242px
}

.priority-group {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    background: #ededed;
    border-radius: 3px;
    padding: 2px;
    width:90%
}
.priority-group > * {
    padding: 0.35rem 0.75rem;
}

.priority-label {
    width: 56%;
    background: #f7f7f7;
    font-size: 0.98rem;
    border-radius: 3px;
    padding: 0.34rem .75rem;
}

.priority-button {
    border: none;
    width: 40%;
    background: #727cf5;
    border-radius: 3px;
    color: #fff;
}

.form-control {
    border: 1px solid black;
    border-radius: 3px;
    color: black;
}

.input-group-text {
    background: #727cf5;
    border: #727cf5;
    border-radius: 0.1875rem 0 0 0.1875rem;
    color: #fff;
    font-size: 0.875rem;
    font-weight: 600;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 100em 0;
  }
}


.load-box {
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: fff;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  height: 35px;
  border-radius: 3px;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  left:0;
  right:0;
  top:0;
  bottom:0;
  transition: all .2s linear;
}

#data-content {
  max-height: 500px;
  overflow-y: scroll;
}
</style>
