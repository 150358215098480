import { createWebHistory, createRouter } from "vue-router";


import Dashboard from "@/views/Dashboard.vue";
import Login from "@/views/Login.vue";

import Empresas from "@/views/empresas/Empresas.vue";
import Irregulares from "@/views/empresas/Irregulares.vue";
import Sectores from "@/views/sectores/Sectores.vue";
import Volantes from "@/views/volantes/Volantes.vue";
import Personal from "@/views/personal/Personal.vue";
import Historico from "@/views/personal/Historico.vue";
import Nomina from "@/views/nomina/Nomina.vue";
import Lockers from "@/views/lockers/Lockers.vue";
import Bajas from "@/views/bajas/Bajas.vue";
import Legales from "@/views/novedades/Legales.vue";
import Solicitudbjm from "@/views/novedades/Solicitudbjm.vue";
import Novedades from "@/views/novedades/Novedades.vue";
import NovedadesHrbp from "@/views/novedades/NovedadesHrbp.vue";
import NovedadesBejerman from "@/views/novedades/NovedadesBejerman.vue";
import HorasNocturnasPlanta from "@/views/novedades/horasNocturnasPlanta.vue";
import Prospecto from "@/views/prospecto/Prospecto.vue";
import Presentismo from "@/views/presentismo/Presentismo.vue";
import MesPresente from "@/views/presentismo/MesPresente.vue";
import MesPresenteZonal from "@/views/presentismo/MesPresenteZonal.vue";
import MesPresenteTemporal from "@/views/presentismo/MesPresenteTemporal.vue";
import Orientacion from '@/views/orientacion/Orientacion.vue';
import Liquidacion from "@/views/liquidacion/Personal.vue";
import LiquidacionCat from "@/views/liquidacion/PersonalCat.vue";
import Mantenimiento from "@/views/mantenimiento/Tickets.vue";
import TrainedByZonal from "@/views/capacitacion/TrainedByZonal.vue";
import Blanquear from "@/views/blanquear/Blanquear.vue";
import Hijos from "@/views/hijos/Hijos.vue";

import AdelantoLiquidacion from "@/views/liquidacion/AdelantoPersonal.vue";
import AdelantoLiquidacionCat from "@/views/liquidacion/AdelantoPersonalCat.vue";

import Recibos from "@/views/liquidacion/Recibos.vue";

import Blog from "@/views/Blog.vue";
import Preguntas from "@/views/Preguntas.vue";

import Permisos from "@/views/permisos/Personal.vue";


import Hempresas from "@/views/hidalgo/Empresas.vue";
import Hpersonal from "@/views/hidalgo/Personal.vue";

import RequiHrbp from "@/views/requisitoria/RequiHrbp.vue";
import RequiLegales from "@/views/requisitoria/RequiLegales.vue";
import RequiGerencia from "@/views/requisitoria/RequiGerencia.vue";
import RequiRRHH from "@/views/requisitoria/RequiRRHH.vue";
import RequiSeleccion from "@/views/requisitoria/RequiSeleccion.vue";
import RequiCostos from "@/views/requisitoria/RequiCostos.vue";

import PresentismoArmado from "@/views/presentismo/PresentismoArmado.vue";

import Liquidacion_z from "@/views/liquidacion_z/Personal.vue";
import LiquidacionCat_z from "@/views/liquidacion_z/PersonalCat.vue";

import Liquidacion_op from "@/views/liquidacion_op/Personal.vue";
import LiquidacionCat_op from "@/views/liquidacion_op/PersonalCat.vue";


const routes = [
 {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
   {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/empresas",
    name: "Empresas",
    component: Empresas,
     meta: {
    reload: true,
  }
  },
  {
    path: "/irregulares",
    name: "Irregulares",
    component: Irregulares,
  },
   {
    path: "/hempresas",
    name: "Hempresas",
    component: Hempresas,
  },
  {
    path: "/hpersonal",
    name: "Hpersonal",
    component: Hpersonal,
  },


{
    path: "/requihrbp",
    name: "RequiHrbp",
    component: RequiHrbp,
  },
  {
    path: "/requilegales",
    name: "RequiLegales",
    component: RequiLegales,
  },
  {
    path: "/requigerencia",
    name: "RequiGerencia",
    component: RequiGerencia,
  },
{
    path: "/requirrhh",
    name: "RequiRRHH",
    component: RequiRRHH,
  },
  {
    path: "/requiseleccion",
    name: "RequiSeleccion",
    component: RequiSeleccion,
  },
  {
    path: "/requicostos",
    name: "RequiCostos",
    component: RequiCostos,
  },




   {
    path: "/sectores",
    name: "Sectores",
    component: Sectores,
  },
   {
    path: "/volantes",
    name: "Volantes",
    component: Volantes,
  },{
    path: "/nomina",
    name: "Nomina",
    component: Nomina,
  },{
    path: "/personal",
    name: "Personal",
    component: Personal,
  },{
    path: "/historico",
    name: "Historico",
    component: Historico,
  },{
    path: "/permisos",
    name: "Permisos",
    component: Permisos,
  },{
    path: "/liquidacion",
    name: "Liquidacion",
    component: Liquidacion,
  },{
    path: "/liquidacion_z",
    name: "Liquidacion_z",
    component: Liquidacion_z,
  },{
    path: "/liquidacion__op",
    name: "Liquidacion_op",
    component: Liquidacion_op,
  },
  {
    path: "/AdelantoLiquidacion",
    name: "AdelantoLiquidacion",
    component: AdelantoLiquidacion,
  },{
    path: "/AdelantoLiquidacionCat",
    name: "AdelantoLiquidacionCat",
    component: AdelantoLiquidacionCat,
  },{
    path: "/liquidacionCat",
    name: "LiquidacionCat",
    component: LiquidacionCat,
  },{
    path: "/liquidacionCat_z",
    name: "LiquidacionCat_z",
    component: LiquidacionCat_z,
  },{
    path: "/liquidacionCat_op",
    name: "LiquidacionCat_op",
    component: LiquidacionCat_op,
  },{
    path: "/recibos",
    name: "Recibos",
    component: Recibos,
  },{
    path: "/lockers",
    name: "Lockers",
    component: Lockers,
  },{
    path: "/bajas",
    name: "Bajas",
    component: Bajas,
  },
  {
    path: "/novedades",
    name: "Legales",
    component: Legales,
  },
  {
    path: "/Solicitudbjm",
    name: "Solicitudbjm",
    component: Solicitudbjm,
  },
  {
    path: "/novedades",
    name: "Novedades",
    component: Novedades,
  },
   {
    path: "/novedadesBejerman",
    name: "NovedadesBejerman",
    component: NovedadesBejerman,
  },
  
   {
    path: "/horasNocturnasPlanta",
    name: "HorasNocturnasPlanta",
    component: HorasNocturnasPlanta,
  },
  {
    path: "/novedadesHrbp",
    name: "NovedadesHrbp",
    component: NovedadesHrbp,
  },
  {
    path: "/prospecto",
    name: "Prospecto",
    component: Prospecto,
  },
  {
    path: "/presentismo",
    name: "Presentismo",
    component: Presentismo,
  },{
    path: "/presentismoarmado",
    name: "PresentismoArmado",
    component: PresentismoArmado,
  },
   {
    path: "/mespresente",
    name: "MesPresente",
    component: MesPresente,
  },{
    path: "/mespresentezonal",
    name: "MesPresenteZonal",
    component: MesPresenteZonal,
  },{
    path: "/mespresentetemporal",
    name: "MesPresenteTemporal",
    component: MesPresenteTemporal,
  },
    {
    path: "/admin/blog",
    name: "Blog",
    component: Blog,
    meta: {
      minStatus: 0
    }
  },
  {
    path: "/admin/Preguntas",
    name: "Preguntas",
    component: Preguntas,
    meta: {
      minStatus: 0
    }
  },
  {
    path: "/orientacion",
    name: "Orientacion",
    component: Orientacion,
  },
  {
    path: "/mantenimiento",
    name: "Mantenimiento",
    component: Mantenimiento,
  },
  {
    path: "/capacitacion",
    name: "TrainedByZonal",
    component: TrainedByZonal,
  },
  {
    path: "/blanquear",
    name: "Blanquear",
    component: Blanquear,
  },
  {
    path: "/hijos",
    name: "Hijos",
    component: Hijos,
  },
 
 
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: 'history'
});

export default router;