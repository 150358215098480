<template>
    <div class="page-content">
                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title"><i class="link-icon" data-feather="box"></i> {{breadcrumbB}}</h6>
                          <p class="card-description">
                           {{descripcion}}
                            </p>


                <div class="row mb-3">
                    <div class="col">
                        <label class="mr-sm-2">Mes</label>
                        <select class="custom-select mr-sm-2" id="mes">
                            <option value="01">Enero</option>
                            <option value="02">Febrero</option>
                            <option value="03">Marzo</option>
                            <option value="04">Abril</option>
                            <option value="05">Mayo</option>
                            <option value="06">Junio</option>
                            <option value="07">Julio</option>
                            <option value="08">Agosto</option>
                            <option value="09">Septiembre</option>
                            <option value="10">Octubre</option>
                            <option value="11">Noviembre</option>
                            <option value="12">Diciembre</option>
                        </select>
                    </div>
                    <div class="col">
                        <label class="mr-sm-2" for="inlineFormCustomSelect">Año</label>
                          <select class="custom-select mr-sm-2" id="anio">
                            <option value="2023">2023</option>
                            <option value="2022">2022</option>
                            <option value="2021">2021</option>
                            <option value="2020">2020</option>
                           
                        </select>
                    </div>
                    <div class="col">
                        <label class="mr-sm-2 w-100" for="">&nbsp;</label>
                        <button  class="btn btn-md btn-success w-100" @click="refrescarPersonal()">Consultar Presentismos</button>
                    </div>
                </div>
          


                        </div>  
                      </div>
                    </div>
                  </div>    

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                           <h6 class="card-title" id="mestext"></h6>
                           <div id="alerta" class="alert alert-danger" role="alert">Esta acción puede tardar unos segundos!, estamos recopilando datos</div>

<div id="loading">
  
 <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                    <div class="card-title w-25"><div class="load-box"></div></div>
                    <hr>
                    <div class="row mb-2">
                        <div class="col-2">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-2">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-4">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-4">
                            <div class="load-box"></div>
                        </div>
                    </div>
                    <hr>
                    <div class="row mb-2">
                        <div class="col-2">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-2">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-4">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-4">
                            <div class="load-box"></div>
                        </div>
                    </div>
                    <hr>
                    <div class="row mb-2">
                        <div class="col-2">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-2">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-4">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-4">
                            <div class="load-box"></div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-2">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-2">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-4">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-4">
                            <div class="load-box"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>



                    
                             <div class="table-responsive">





                            <table id="zonales" class="table">
                              <thead>
                                <tr>
                                  <th style="z-index: 99999;">DNI</th>
                                  <th style="z-index: 99999;">Legajo</th>
                                  <th style="z-index: 99999;">Nombre</th>
                                  <th style="z-index: 99999;">Categoria</th>
                                  <th style="z-index: 99999;">Sector</th>

                                  <th>01</th>
                                  <th>02</th>
                                  <th>03</th>
                                  <th>04</th>
                                  <th>05</th>
                                  <th>06</th>
                                  <th>07</th>
                                  <th>08</th>
                                  <th>09</th>
                                  <th>10</th>

                                  <th>11</th>
                                  <th>12</th>
                                  <th>13</th>
                                  <th>14</th>
                                  <th>15</th>
                                  <th>16</th>
                                  <th>17</th>
                                  <th>18</th>
                                  <th>19</th>
                                  <th>20</th>

                                  <th>21</th>
                                  <th>22</th>
                                  <th>23</th>
                                  <th>24</th>
                                  <th>25</th>
                                  <th>26</th>
                                  <th>27</th>
                                  <th>28</th>
                                  <th>29</th>
                                  <th>30</th>

                                  <th>31</th>
                                  <th>Presentes</th>
                                 
                                 
                                
                                 
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="zonales in zonales" v-bind:key="zonales">
                                    <td>{{zonales.dni}}</td>
                                    <td>{{zonales.legajo}}</td>
                                    <td>{{zonales.nombre}} <br> {{zonales.apellido}}</td>
                                    <td>{{zonales.categoria}}</td>
                                    <td>{{zonales.sector}}</td>

                                     <td v-for="(number, index) in zonales.asistencia" :key="index">
                                      <span v-if="zonales.asistencia[index].asistencia=='P'" class="badge badge-success" >{{zonales.asistencia[index].asistencia}}</span>
                                      <span v-else class="badge badge-danger" >{{zonales.asistencia[index].asistencia}}</span><img @click="novedad(zonales.nombre+' '+zonales.apellido,zonales.dni,zonales.legajo,zonales.sector,index)" data-toggle="modal" data-target=".nuevo" class="editar" v-if="zonales.asistencia[index].asistencia=='A'" src="https://findicons.com/files/icons/2813/flat_jewels/256/edit.png" width="20px">

                                     </td>
                                      <td>{{zonales.presente}}</td>

                                     

                                   
                                
                                   
                                  
                                </tr>
                              </tbody>
                            </table>
                          </div>







                        </div>
                      </div>
                    </div>
                  </div>


             

            </div>


            <!-- AGREGAR NOVEDAD A PERSONAL PLANTA-->

            <div class="modal fade nuevo" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">


                <div class="modal-dialog modal-lg">
                  <div class="modal-content" style="padding-bottom: 130px">
                    <div id="espera"><br><br><br>
                        <center>ESPERE UN INSTANTE! <br> ESTAMOS GUARDANDO Y PROCESANDO LA NOVEDAD DENTRO DEL PRESENTISMO<br>NO CIERRE EL NAVEGADOR POR FAVOR</center>
                    </div>
                    <div class="modal-header">
                      <h5 class="modal-title h4" id="myLargeModalLabel">Asignar novedad a <span id="nombre_novedad"></span></h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="cerrar_novedad">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      



<table width="100%">
    

<td colspan="3" width="70%">
   <div class="form-group formulario" data-legajo="" data-empresa="">
      <label for="exampleFormControlInput1">*Novedad *</label><br>
      <select data-empresa="" data-legajos="" id="novedades" class="js-example-basic novedadsel" style="width:100%;width: 100%;border: 1px solid #9b9b9b;color: #2e2929;">
         <option value="0">-- BUSCA Y SELECCIONA NOVEDAD --</option>
         <option data-condicion="y" value="21">Accidente</option>
         <option data-condicion="y" value="100">Ausencia Injustificada</option>

         <option data-condicion="y" value="105">Descuento dias de ingreso</option>
         <option data-condicion="y" value="106">Descuento dias de egreso</option>

         <option data-condicion="y" value="20">Enfermedad</option>
         
         <option data-condicion="y" value="F">Franco</option>
         <option data-condicion="m" value="6">Feriado</option>
           <option data-condicion="m" value="267">Feriado Supervisor</option>
            <option data-condicion="m" value="7">Feriado 2/8 ART31 CCT</option>
         

         <option data-condicion="y" value="38">Lic. vacuna Covid</option>
         <option data-condicion="y" value="129">Lic. Maternidad</option>
         <option data-condicion="y" value="25">Lic. Examen</option>
         <option data-condicion="y" value="116">Lic. Excedencia</option>
         <option data-condicion="y" value="24">Lic. Fallecimiento (Conyuge e Hijos)</option>
         <option data-condicion="y" value="24">Lic. Fallecimiento (Padre/Madre)</option>
         <option data-condicion="y" value="24">Lic. Fallecimiento (Hermanos)</option>
         <option data-condicion="y" value="24">Lic. Fallecimiento (Abuelos o Suegros)</option>
         <option data-condicion="y" value="22">Lic. Matrimonio</option>
         <option data-condicion="y" value="26">Lic. Mudanza</option>
         <option data-condicion="y" value="23">Lic. Nacimiento</option>
         <option data-condicion="y" value="28">Lic. Vacaciones</option>
         <option data-condicion="y" value="27">Lic. donac. sangre</option>
         <option data-condicion="y" value="31">Lic. tram. jud</option>

         <option data-condicion="y" value="PM">Presente Manual</option>

         <option data-condicion="y" value="101">Suspension Disciplinaria</option>
         <option data-condicion="y" value="122">Suspension Operartiva</option>
        
         
         
       
      </select>
      
   </div>
   
   <br>
   <table width="100%">
      <tbody>
         <tr>
            <td>Desde *</td>
            <td>Hasta *</td>
         </tr>
         <tr>
            <td><input style="width: 50%;" value="" type="date" id="inix"  min=""></td>
            <td><input style="width: 50%;" value="" type="date" id="finix" min=""></td>
         </tr>
      </tbody>
   </table>


   <div class="form-group">
      <label for="exampleFormControlInput1">Certificado/Documentación</label><br><br>
      <div class="custom-file" style="height:auto;">
         <center><input type="file" id="imagen" data-legajo=""  style="display: block: important!;">
         </center>
      </div>
   </div>
   <div class="form-group"><label for="exampleFormControlTextarea1">Detalle</label><br><textarea style="width: 100%;border: 1px solid #9b9b9b;color: #2e2929;" class="form-control" id="detalle" rows="3" width="100%"></textarea></div>
</td>
<td style="background-color: #e1e1e1;border: 43px solid white;">
    <label for="exampleFormControlInput1">Vista previa del certificado</label><br>
    <img width="100%" id="preview" src="">
</td>

</table>


<center> <button class="btn btn-success w-25" style="width: 100%" @click="enviarNovedad()">GUARDAR NOVEDAD</button></center>





                    </div>
                  </div>
                </div>
              </div>


     

</template>

<script>

import axios from 'axios';
import $ from 'jquery';
require('datatables.net');
import 'datatables.net-fixedcolumns';
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();

import firebase from "firebase/app";
import "firebase/database";
import "firebase/storage";
const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));
let storageRef = firebase.storage().ref();
export default {
    name: 'Personal',
   
    data() {
        return {
            breadcrumbA: 'Inicio',
            breadcrumbB: 'Zonales',
            descripcion: 'En este modulo usted podra visualizar y administrar todo  el personal de la empresa',
            triggers: {load:true},
            
            zonales: [],
            modalAdministrar: {
                open: false,
                legajo: null,
                nombre: null,
                apellido: null,
                operacion: null,
                idDistribucion: null,
            },
            modalLegales: {
                open: false,
                dni: null,
                nombre: null,
                apellido: null,
            },
        }
    },
    methods: {


        enviarNovedad() {


if ($("#novedades option:selected").val()!=0||$("#inix").val()!=""||$("#finix").val()!="") {

       localStorage.setItem("enviado_novedad",0)
       $("#espera").show();


              const addZero= (i) => {
                  if (i < 10) {
                    i = "0" + i;
                  }
                  return i;
                }
   
   
               var legajo = localStorage.getItem("novedad_legajo");
               var empresa =localStorage.getItem("novedad_sector");


               var nombre = localStorage.getItem("novedad_nombre");
               var novedad = $("#novedades option:selected").text();
               var condicion = "y";
               var novedad_id = $("#novedades option:selected").val();
               var horas = "-";
               var desde = $("#inix").val();
               var hasta = $("#finix").val();
               var certificado = $("#preview").attr("src");
               var detalle = $("#detalle").val();
               var responsable = JSON.parse(sessionStorage.getItem('userData')).leg_apellido+" "+JSON.parse(sessionStorage.getItem('userData')).leg_nombre;
               var operacion_raiz = "Food Service Planta";
   
   

              var h = new Date();
              var hora = addZero(h.getHours());
              var minutos = ("0" + h.getMinutes()).substr(-2);
              var segundos = ("0" + h.getSeconds()).substr(-2);
              var horaActual = hora + ':' + minutos + ':' + segundos;

              var today = new Date();
              var m = today.getMonth() + 1;
              var mes = (m < 10) ? '0' + m : m;
              var dia = today.getDate();
              dia = (dia < 10) ? '0' + dia : dia;
              var fechaActual = today.getFullYear() + '-' + mes + '-' + dia;
              var pedido = fechaActual + ' ' + horaActual;

               var estatus = "Resuelto";

              
                            if (certificado=="") {
                                certificado="-";

                                firebase.database().ref('novedades_usuarios_test').push({

                                                      fecha: pedido,
                                                      fecha_novedad: fechaActual,
                                                      responsable: responsable,
                                                      operacion_raiz:operacion_raiz,
                                                      legajo: legajo,
                                                      nombre: nombre,
                                                      id_novedad: 'rojo',
                                                      novedad: novedad,
                                                      bejerman_id: novedad_id,
                                                      detalle: detalle,
                                                      status: estatus,
                                                      desde:desde,
                                                      hasta:hasta,
                                                      checking:1,
                                                      responsable_dni:JSON.parse(sessionStorage.getItem('userData')).leg_numdoc,
                                                      certificado:certificado,
                                                      horas:horas,
                                                      obs:1

                                                    }, function (error) {

                                                      if (error) {
                                                        alert("Ocurrio un error! verifique su conexión a internet!")
                                                      } else {



                                                        localStorage.setItem("enviado_novedad",1)
                                                          
                                                        
                                                      }

                                                    });

                                }else{

                                              var mountainsRef = storageRef.child('novedades/'+new Date().getTime()+'-'+Math.ceil(Math.random()*1000));
                                              mountainsRef.putString(certificado, 'data_url').then(function(snapshot) {
                                                
                                                snapshot.ref.getDownloadURL().then(function(downloadURL) {
                                                   firebase.database().ref('novedades_usuarios_test').push({

                                                              fecha: pedido,
                                                              fecha_novedad: fechaActual,
                                                              responsable: responsable,
                                                              operacion_raiz:operacion_raiz,
                                                              legajo: legajo,
                                                              nombre: nombre,
                                                              id_novedad: 'rojo',
                                                              novedad: novedad,
                                                              bejerman_id: novedad_id,
                                                              detalle: detalle,
                                                              status: estatus,
                                                              desde:desde,
                                                              hasta:hasta,
                                                              checking:1,
                                                              responsable_dni:JSON.parse(sessionStorage.getItem('userData')).leg_numdoc,
                                                              certificado:downloadURL,
                                                              horas:horas,
                                                              obs:1

                                                            }, function (error) {

                                                              if (error) {
                                                                alert("Ocurrio un error! verifique su conexión a internet!")
                                                              } else {

                                                                localStorage.setItem("enviado_novedad",1)
                                                                
                                                                
                                                                  
                                                                
                                                              }

                                                            });
                                                   
                                                })
                                        });

                                }

                  
                   
            var enviado = setInterval(function(){
                 if (localStorage.getItem("enviado_novedad")==1) {
                    this.refrescarPersonal(); 
                    $("#cerrar_novedad").click();
                    $("#espera").hide()
                    clearInterval(enviado)
                    localStorage.setItem("enviado_novedad",0)
                 }
                 
             }.bind(this), 2000) 


          /* CIERRA SUBIR DATOS A FIREBASE */


}else{

    alert("DEBE COMPLETAR LOS CAMPOS OBLIGATORIOS!")
}


},
       
        async novedad(nombre,dni,legajo,sector,index){
            $("#nombre_novedad").html(nombre)
            $('#preview').attr('src', '');
            localStorage.removeItem("novedad_imagen")
            localStorage.removeItem("novedad_nombre")
            localStorage.removeItem("novedad_dni")
            localStorage.removeItem("novedad_legajo")
            localStorage.removeItem("novedad_sector")



            localStorage.setItem("novedad_nombre",nombre)
            localStorage.setItem("novedad_dni",dni)
            localStorage.setItem("novedad_legajo",legajo)
            localStorage.setItem("novedad_sector",sector)

            var day = index+1;
            if (day<10) {
              day = "0"+day;
            }


            var mesx = localStorage.getItem("mes_x",mes);
            if (mesx<10) {
              mesx = "0"+mesx;
            }
           

            setTimeout(function(){
                $(".modal-backdrop.show").prop("style","opacity: 0.9 !important;")
                $(".modal-lg, .modal-xl").prop("style","max-width: 90% !important;")
                
               
                var aniox = localStorage.getItem("anio_x",anio);

                $("#inix,#finix").prop("min",aniox+"-"+mesx+"-"+day);

                $("#imagen").change(function(){

                    var fileInput = document.getElementById('imagen');

                    var reader = new FileReader();
                    reader.readAsDataURL(fileInput.files[0]);

                    reader.onload = function () {
                    var datas = reader.result;
                    

                     new Promise( async function(resolve,reject){
                        var newWidth = 300;

                        // We create an image to receive the Data URI
                        var img = document.createElement('img');

                        // When the event "onload" is triggered we can resize the image.
                        img.onload = function()
                        {        
                            // We create a canvas and get its context.
                            var canvas = document.createElement('canvas');
                            var ctx = canvas.getContext('2d');

                          


                        var imgwidth = img.width;
                        var imgheight = img.height;
                        canvas.width = newWidth;
                        canvas.height = (imgheight / imgwidth) * newWidth;
                        ctx.drawImage(
                        this, 0, 0, imgwidth, imgheight, 0, 0, newWidth, (imgheight / imgwidth) * newWidth
                        );

                            // We resize the image with the canvas method drawImage();
                            //ctx.drawImage(this, 0, 0, width, height);


                            var dataURI = canvas.toDataURL();

                            // This is the return of the Promise
                            resolve(dataURI);
                        };

                        // We put the Data URI in the image's src attribute
                        img.src = datas;

                        }).then(function(result) {

                       console.log(result)

                        $('#preview').attr('src', result);
                        $('#preview').attr('style', 'width: 100%');
                        localStorage.setItem("novedad_imagen",result)
                        
                                
                    })





                    };
                    reader.onerror = function (error) {
                    console.log('Error: ', error);
                    };
                });





            },200)



        },
      
        async obtenerPersonal(dias,mes,anio){


          localStorage.setItem("mes_x",mes);
          localStorage.setItem("anio_x",anio);

            
             var zonales = [];
          

            if (mes<10) {
              mes = "0"+mes;
            }

            console.log(dias+":"+mes+":"+anio)

           $('#mes option[value='+mes+']').attr('selected','selected');
           $('#anio option[value='+anio+']').attr('selected','selected');
           $("#mestext").html("MES:<b>"+mes+"</b> / AÑO:<b>"+anio+"</b>")


            const axiosrequest1 = axios.get('https://auth.foodservice.com.ar/?type=presentismo_reporte&desde='+anio+'-'+mes+'-01&hasta='+anio+'-'+mes+'-'+dias+'&mes='+mes+'&anio='+anio+'&access_token=1234567');
           



            await axios.all([axiosrequest1]).then(
            axios.spread((personal) => {
               

                 


                   $.each(personal.data, function(cla, val) {
                   
                        if (val.sector=="SECTOR ZONALES") {
                            zonales.push({

                                "legajo": val.legajo,
                                "nombre": val.nombre,
                                "apellido": val.apellido,
                                "categoria": val.categoria,
                                "sector": val.sector,
                                "asistencia": val.asistencia,
                                "dni": val.dni,
                                "dia":val.dia,
                                "presente":val.presente
                            })
                        }
                     
                });


                  


                this.zonales = zonales;
               
               $(".table-responsive").css("visibility","visible");
                     $("#loading").hide();
                     $("#alerta").slideUp();
                })).then(() => {
                    $('#zonales').DataTable({

  initComplete: function () {
            this.api().columns(4).every( function () {
                var column = this;
                console.log(column.header())
                var select = $('<select><option value="" style="color: #060606;">TODOS LOS SECTORES</option></select>')
                    .appendTo( $(column.header()).empty() )
                    .on( 'change', function () {
                        var val = $.fn.dataTable.util.escapeRegex(
                            $(this).val()
                        );
 
                        column
                            .search( val ? '^'+val+'$' : '', true, false )
                            .draw();
                    } );
                
                column.data().unique().sort().each( function ( d, j ) {
                    
                    select.append( '<option value="'+d+'">'+d+'</option>' )
                } );
            } );
        },

                        "language": {
                            "decimal": ",",
                            "thousands": ".",
                            "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                            "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                            "infoPostFix": "",
                            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
                            "loadingRecords": "Cargando...",
                            "lengthMenu": "Mostrar _MENU_ registros",
                            "paginate": {
                                "first": "Primero",
                                "last": "Último",
                                "next": "Siguiente",
                                "previous": "Anterior"
                            },
                            "processing": "Procesando...",
                            "search": "Buscar:",
                            "searchPlaceholder": "Término de búsqueda",
                            "zeroRecords": "No se encontraron resultados",
                            "emptyTable": "Ningún dato disponible en esta tabla",
                            "aria": {
                                "sortAscending":  ": Activar para ordenar la columna de manera ascendente",
                                "sortDescending": ": Activar para ordenar la columna de manera descendente"
                            },
                        },

                         fixedColumns: {
                            left: 5
                        },
                         "scrollx": true,
                        "search": {regex:true},
                        dom: "<'#boton' B f><'#tabla' t><'#paginador' p>",
                        responsive: true,
                        nowrap: true,
                        buttons: [
                            'copy',
                            'excel',
                            'csv',
                            'pdf'
                        ]
                    });

                    // Bfrtip


                    $("#boton ").prop("style","position: absolute;width: 100%;")
                    $("#tabla ").prop("style","overflow-y: hidden;")


                    if (dias==31) {
                       $('#zonales').DataTable().column( 35 ).visible( true );
                       $('#zonales').DataTable().column( 34 ).visible( true );
                       $('#zonales').DataTable().column( 33 ).visible( true );
                    }

                    if (dias==30) {
                       $('#zonales').DataTable().column( 35 ).visible( false );
                       $('#zonales').DataTable().column( 34 ).visible( true );
                       $('#zonales').DataTable().column( 33 ).visible( true );
                    }

                    if (dias==29) {
                       $('#zonales').DataTable().column( 35 ).visible( false );
                       $('#zonales').DataTable().column( 34 ).visible( false );
                       $('#zonales').DataTable().column( 33 ).visible( true );
                    }

                    if (dias==28) {
                       $('#zonales').DataTable().column( 35 ).visible( false );
                       $('#zonales').DataTable().column( 34 ).visible( false );
                       $('#zonales').DataTable().column( 33 ).visible( false );
                    }
                   

                     

                });


                
        },
        refrescarPersonal(){

                $(".table-responsive").css("visibility","hidden");
                     $("#loading").show();
                     $("#alerta").slideDown();
                     $("#espera").hide()
          
                $('#zonales').dataTable().fnDestroy();
                var mesActual  = $('#mes option:selected').val();
                var anioActual = $('#anio option:selected').val();
                var diasMes = new Date(anioActual, mesActual, 0).getDate();

                  if (mesActual<10) {
                    mesActual = mesActual.replace("0","");
                  }


                  console.log(diasMes+" - "+mesActual+" - "+anioActual)
                 this.obtenerPersonal(diasMes,mesActual,anioActual);
            
        },
    },
    mounted() {



        const fecha = new Date();
        const hoy = fecha.getDate();
        const mesActual = fecha.getMonth() + 1; 
        const anioActual = fecha.getFullYear();
        var diasMes = new Date(anioActual, mesActual, 0).getDate();
      
        this.obtenerPersonal(diasMes,mesActual,anioActual);


        

    },
};
</script>

<style scoped>

#espera {
    width: 100%;
    height: 100%;
    background-color: whitesmoke;
    position: absolute;
    z-index: 999;
    font-size: 200%;
    font-weight: bold;
    display: none;
}

.modal-backdrop.show {
    opacity: 0.9 !important;
}
@media (min-width: 992px)
.modal-lg, .modal-xl {
    max-width: 1059px !important;
    /* height: 360px; */
    padding-bottom: 116px;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999999;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

table.dataTable thead>tr>td.sorting, table.dataTable thead>tr>td.sorting_asc, table.dataTable thead>tr>td.sorting_desc, table.dataTable thead>tr>th.sorting, table.dataTable thead>tr>th.sorting_asc, table.dataTable thead>tr>th.sorting_desc {
    padding-right: 30px;
    background-color: #ffffff;
}

table.dataTable {
    clear: both;
    margin-top: 52px!important;
    margin-bottom: 6px!important;
    max-width: none!important;
    border-collapse: separate!important;
    border-spacing: 0;
}


table.dataTable tbody th, table.dataTable tbody td {
    padding: 8px 10px;
    background-color: #fff;
}

 th, td { white-space: nowrap; }
    div.dataTables_wrapper {
        width: 800px;
        margin: 0 auto;
    }

.td {
    
    border: 1px solid #e6e6e6;
}

.load-box {
    background: red;
}


@keyframes placeHolderShimmer {
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 100em 0;
  }
}


.load-box {
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: fff;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  height: 24px;
  -webkit-backface-visibility: hidden;
  left:0;
  right:0;
  top:0;
  bottom:0;
  transition: all .2s linear;
}

#loading{
 
  width: 100%;
}

.table-responsive{
  visibility: hidden;
}

.editar{
    cursor: pointer;
}

input[type="file"] {
    display: block;
}
</style>

