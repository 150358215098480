<template>
    <div class="page-content">




                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title"><i class="link-icon" data-feather="box"></i> {{breadcrumbB}}</h6>
                          <p class="card-description">
                           {{descripcion}}
                            </p>
                        </div>  
                      </div>
                    </div>
                  </div>    

                 <lista-carga v-if="triggers.load"></lista-carga>

                  <div class="row" v-else>
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <div class="table-responsive">
                            <table id="empresas" class="table">
                              <thead>
                                <tr>
                                    <th>PERFIL</th>
                                    <th>EMPRESA</th>
                                    <th>LEGAJO</th>
                                    <th>NOMBRE</th>
                                    <th>APELLIDO</th>
                                    <th>TIPO DOC.</th>
                                    <th>NUM. DOC.</th>
                                    <th>CUIL</th>
                                    <th>NACIONALIDAD</th>
                                    <th>ESTADO CIVIL</th>
                                    <th>SEXO</th>
                                    <th>FEC NAC.</th>
                                    <th>CBU</th>


                                    <th>DOMICILIO</th>
                                    <th>DEPTO</th>
                                    <th>ENTRE CALLES</th>
                                    <th>LOCALIDAD</th>
                                    <th>TELEFONO</th>
                                    <th>EMAIL</th>


                                    <th>FECHA ALTA</th>
                                    <th>FECHA ANT. RECONOCIDA</th>
                                    <th>CENTRO COSTOS</th>
                                    <th>CENTRO DE COSTOS DESCRIPCION</th>
                                    <th>CENTRO TRABAJO</th>
                                    <th>CENTRO TRABAJO DESCRIPCION</th>
                                    <th>CONVENIO</th>
                                    <th>CONVENIO DESCRIPCION</th>
                                    <th>CATEGORIA</th>
                                    <th>CATEGORIA DESCRIPCION</th>
                                    <th>PUESTO</th>
                                    <th>PUESTO DESCRIPCION</th> 
                                    <th>SECCIÓN</th>
                                    <th>SECCIÓN DESCRIPCION</th>
                                    <th>AGRUP. CONTABLE</th>
                                    <th>AGRUP. CONTABLE DESCRIPCION</th>
                                    <th>SINDICATO COD.</th>
                                    <th>SINDICATO DESCRIPCION</th>
                                    <th>OBRA SOCIAL</th>
                                    <th>MOD. CONTRATACIÓN</th>
                                    <th>SUELDO BASICO</th>
                                    <th>TIPO EMPLEADO</th>
                                    <th>TIPO EMPLEADO DESCRIPCION</th>
                                    
                                   

                            

                                  
                                  
                                </tr>
                              </thead>
                              <tbody>
                                
                                <tr v-for="personal in personal" v-bind:key="personal" :class="{'fila-no-distribuido':personal.cod=='-'}">



                                    
                                    <td v-if="personal.avatar=='-'" style="color:#fff;">2<img width="40px" height="40px" style="border: 1px solid #8b8b8b99;object-fit:cover" src="https://thumbs.dreamstime.com/b/perfil-de-usuario-vectorial-avatar-predeterminado-179376714.jpg"></td>
                                    <td v-else style="color:#fff;">1<img width="40px" height="40px" style="object-fit:cover" :src="personal.avatar"></td>

                                    <td v-if="personal.razonsocial=='Food Service America'">01</td>
                                    <td v-else>02</td>
                                   
                                  
                                   
                                    <td>{{personal.legajo}}</td>

                                    <td>{{personal.nombre}}</td>
                                    
                                    <td>{{personal.apellido}}</td>

                                    <td>DNI</td>

                                    <td>{{personal.dni}}</td>
                                    <td>{{personal.cuil}}</td>
                                    <td>{{personal.nacionalidad}}</td>
                                    <td>{{personal.estadocivil}}</td>

                                      <td v-if="personal.sexo=='1'">Maculino</td>
                                    <td v-else>Femenino</td>

                                    <td>{{personal.nacimiento}}</td>
                                    <td>{{personal.cbu}}</td>

                                    <td>{{personal.domicilio}}</td>
                                    <td>{{personal.depto}}</td>
                                    <td>{{personal.entrecalles}}</td>
                                    <td>{{personal.localidad}}</td>
                                    
                                    <td>{{personal.telefono}}</td>
                                    <td>{{personal.email}}</td>





                                    <td>{{personal.ingreso}}</td>
                                    <td>{{personal.ingreso_reco}}</td>
                                    <td>{{personal.operacion_id}}</td>
                                    <td>{{personal.operacion}}</td>
                                      <td>{{personal.operacion_id}}</td>
                                    <td>{{personal.operacion}}</td>
                                    <td>{{personal.convencionado}}</td>
                                     <td v-if="personal.convencionado=='1'">CCT</td>
                                    <td v-else>FC</td>
                                    <td>{{personal.categoria_id}}</td>
                                    <td>{{personal.categoria}}</td>
                                     <td>{{personal.categoria_id}}</td>
                                    <td>{{personal.categoria}}</td>
                                    <td>{{personal.seccion_id}}</td>
                                     <td>{{personal.seccion}}</td>
                                    
                                     <td>{{personal.contable_id}}</td>
                                     <td>{{personal.contable_descri}}</td>

                                     
                                      
                                     
                                    <td>{{personal.sin_codigo}}</td>
                                     <td>{{personal.sin_descrip}}</td>
                                     <td>{{personal.oso_descrip}}</td>
                                     <td>{{personal.mpr_descrip}}</td>
                                     <td>${{personal.salario}}</td>
                                     <td>-</td>
                                     <td>-</td>

<!-- 

  <td v-if="personal.razonsocial=='Food Service America'"><span class="badge badge-success">Food Service America</span></td>
                                    <td v-else><span class="badge badge-info">Food Service Catering</span></td>

-->
                                    
                                    
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                <modal-administrar
                    v-if="modalAdministrar.open" 
                    :empresa="modalOperacionId"
                    :legajoEmpleado="modalAdministrar.legajo"
                    :nombreEmpleado="modalAdministrar.nombre"
                    :apellidoEmpleado="modalAdministrar.apellido"
                    :operacion="modalAdministrar.operacion"
                    :idDistribucion="modalAdministrar.idDistribucion"
                    @cerrar-modal-asignar="refrescarPersonal($event)"
                >
                </modal-administrar>


        <modal-observar
            v-if="modalObservar.open" 
                    :dniEmpleado="modalObservar.dni"
                    :legajoEmpleado="modalObservar.legajo"
                    :nombreEmpleado="modalObservar.nombre"
                    :apellidoEmpleado="modalObservar.apellido"
                   
            @cerrar-modal-observar="refrescarPersonal($event)"
            v-on:keyup.enter="modalObservar.open=false"
        ></modal-observar>


         <modal-vacunas
            v-if="modalVacunas.open" 
                    :dniEmpleado="modalVacunas.dni"
                    :legajoEmpleado="modalVacunas.legajo"
                    :nombreEmpleado="modalVacunas.nombre"
                    :apellidoEmpleado="modalVacunas.apellido"
                    :certificadoEmpleado="modalVacunas.certificado"
                   
            @cerrar-modal-observar="refrescarPersonal($event)"
            v-on:keyup.enter="modalVacunas.open=false"
        ></modal-vacunas>

                <modal-legales
                    v-if="modalLegales.open" 
                    :dniEmpleado="modalLegales.dni"
                    :nombreEmpleado="modalLegales.nombre"
                    :apellidoEmpleado="modalLegales.apellido"
                    :legajoEmpleado="modalLegales.legajo"
                    @cerrar-modal-legales="refrescarPersonal($event)"
                ></modal-legales>

            </div>
     

</template>

<script>

import axios from 'axios';
import $ from 'jquery';
require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();
import ListaCarga from '../ListaCarga.vue';
import ModalAdministrar from './ModalAdministrar.vue';
import ModalObservar from './ModalObservar.vue';
import modalVacunas from './ModalVacunas.vue';
import ModalLegales from './ModalLegales';
const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));

export default {
    name: 'Personal',
    components: {ListaCarga, ModalAdministrar, ModalLegales,ModalObservar,modalVacunas},
    data() {
        return {
            breadcrumbA: 'Inicio',
            breadcrumbB: 'Personal',
            descripcion: 'En este modulo usted podra visualizar y administrar todo  el personal de la empresa',
            triggers: {load:true},
            personal: [],
            novedades: [],
             modalObservar: {
                open: false,
                dni: null,
                nombre: null,
                legajo: null,
                apellido: null,
            },
             modalVacunas: {
                open: false,
                dni: null,
                nombre: null,
                legajo: null,
                apellido: null,
                certificado:null
            },
            modalAdministrar: {
                open: false,
                legajo: null,
                nombre: null,
                apellido: null,
                operacion: null,
                idDistribucion: null,
            },
            modalLegales: {
                open: false,
                dni: null,
                nombre: null,
                apellido: null,
            },
        }
    },
    methods: {
        isEditor(modulo){
      
          if (userPermission.find( record => record.ModulosAccess === modulo)) {
            var status =  userPermission.find( record => record.ModulosAccess === modulo).Editor;
            // console.log(modulo+' : '+status)
            return status;
          }else{
            console.log(modulo+' : '+false)
            return false;
          }
        },
        observarPersonalId(dni, nombre, apellido, legajo){
            this.modalObservar.dni = dni;
            this.modalObservar.nombre = nombre;
            this.modalObservar.apellido = apellido;
            this.modalObservar.legajo = legajo;

            console.log(apellido)
            setTimeout(() => {
                this.modalObservar.open = true;
            }, 200);
        },
        vacunas(dni, nombre, apellido, legajo,certificado){
            

            this.modalVacunas.dni = dni;
            this.modalVacunas.nombre = nombre;
            this.modalVacunas.apellido = apellido;
            this.modalVacunas.legajo = legajo;

            if (certificado!=null) {
                this.modalVacunas.certificado = certificado;
            }
            

            console.log(apellido)
            setTimeout(() => {
                this.modalVacunas.open = true;
            }, 200);
        },
        abrirModalAdministrar(legajo,nombre,apellido,operacion,idDistibucion,empleade){
            console.log(empleade);

            this.modalAdministrar.legajo = legajo;
            this.modalAdministrar.nombre = nombre;
            this.modalAdministrar.apellido = apellido;
            this.modalAdministrar.operacion = (operacion == '-') ? undefined : operacion;
            this.modalAdministrar.idDistribucion = idDistibucion;
            this.modalAdministrar.open = true;
        },
        abrirModalLegales(dni,nombre,apellido,legajo){
            this.modalLegales.dni = dni;
            this.modalLegales.nombre = nombre;
            this.modalLegales.apellido = apellido;
            this.modalLegales.legajo = legajo;
            this.modalLegales.open = true;
        },
        async obtenerPersonal(){
            this.triggers.load = true;
            const axiosrequest1 = axios.get('https://consumos.foodservice.com.ar/api/empresas');
            const axiosrequest2 = axios.get('https://apprrhh-707b9.firebaseio.com/personal.json?print=pretty');
            const axiosrequest3 = axios.get('https://aplicativos.firebaseio.com/distribucion.json?print=pretty');
            const axiosrequest4 = axios.get('https://aplicativos.firebaseio.com/zonal.json?print=pretty');
            const axiosrequest5 = axios.get('https://aplicativos.firebaseio.com/categorias.json?print=pretty');
            const axiosrequest6 = axios.get('https://aplicativos.firebaseio.com/legales.json?print=pretty');
            const axiosrequest7 = axios.get('https://aplicativos.firebaseio.com/sectores.json?print=pretty');

            const axiosrequest8 = axios.get('https://apprrhh-707b9.firebaseio.com/personal_cat.json?print=pretty');
            const axiosrequest9 = axios.get('https://aplicativos.firebaseio.com/observacion_personal.json?print=pretty');
            const axiosrequest10 = axios.get('https://aplicativos.firebaseio.com/manipulacion.json?print=pretty');
            const axiosrequest11 = axios.get('https://aplicativos.firebaseio.com/certificado_vacunacion.json?print=pretty');
            const axiosrequest12 = axios.get('https://aplicativos.firebaseio.com/vacunas_personal.json?print=pretty');
            const axiosrequest13 = axios.get('https://aplicativos.firebaseio.com/perfil.json?print=pretty');

            await axios.all([axiosrequest1, axiosrequest2, axiosrequest3, axiosrequest4, axiosrequest5, axiosrequest6, axiosrequest7,axiosrequest8,axiosrequest9,axiosrequest10,axiosrequest11,axiosrequest12,axiosrequest13]).then(
            axios.spread((operaciones, personal, distribucion, zonal, categorias, legales, sectores,personal_cat,observaciones,manipulacion,vacunacion,dosis,avatar) => {
                var lista = [];
                var observacion = false;

                


                const result_categoria = (id) => {
                    var categoria = "";
                    $.each(categorias.data, function(clave, value) {

                        if (value.codigo == id) {
                            categoria = value.nombre;
                        }
                    })
                    return categoria;
                }

                const result_distribucion = (id) => {
                    var distri = "";
                    $.each(distribucion.data, function(clave, value) {
                        $.each(value, function(k, v) {
                            if (v.legajo == id) {
                                distri = {
                                    operacion: clave,
                                    idDistribucion: k
                                }                               
                            }
                        })

                    });
                    return distri;
                }

                const result_operacion = (id) => {
                    var operacion = "";
                  
                    // Busca primero al empleado en sectores que es mas pequeño
                    Object.keys(sectores.data).forEach((sector)=>{
                        // console.log(sectores.data[sector].id_empresa +"=="+id);
                        if (sectores.data[sector].id_empresa == id) {
                            operacion = sectores.data[sector].operacion;
                           

                        }
                    })
                    // Si no encontro un sector asociado busca una empresa asociada
                    if (operacion == "") {
                        $.each(operaciones.data, function(clave, value) {
                            if (value.id == id) {
                                operacion = value.name;
                             
                            }
                        })
                    }
                    return operacion;
                }




                const result_madre = (id) => {
                    var operacion = "";
                  
                    // Busca primero al empleado en sectores que es mas pequeño
                    Object.keys(sectores.data).forEach((sector)=>{
                        // console.log(sectores.data[sector].id_empresa +"=="+id);
                        if (sectores.data[sector].id_empresa == id) {
                            operacion = "-";
                           

                        }
                    })
                    // Si no encontro un sector asociado busca una empresa asociada
                    if (operacion == "") {
                        $.each(operaciones.data, function(clave, value) {
                            if (value.id == id) {
                                operacion = value.empresa_madre;
                             
                            }
                        })
                    }
                    return operacion;
                }




const result_tipo = (id) => {
                    var operacion = "";
                  
                    // Busca primero al empleado en sectores que es mas pequeño
                    Object.keys(sectores.data).forEach((sector)=>{
                        // console.log(sectores.data[sector].id_empresa +"=="+id);
                        if (sectores.data[sector].id_empresa == id) {
                            operacion = "Planta Central";
                           

                        }
                    })
                    // Si no encontro un sector asociado busca una empresa asociada
                    if (operacion == "") {
                        $.each(operaciones.data, function(clave, value) {
                            if (value.id == id) {
                                operacion = value.tipo_empresa;
                             
                            }
                        })
                    }
                    return operacion;
                }



                const result_operacion_bejerman = (id) => {
                    var operacion = "";
                  
                    // Busca primero al empleado en sectores que es mas pequeño
                    Object.keys(sectores.data).forEach((sector)=>{
                        // console.log(sectores.data[sector].id_empresa +"=="+id);
                        if (sectores.data[sector].id_empresa == id) {
                            operacion = sectores.data[sector].id;
                           

                        }
                    })
                    // Si no encontro un sector asociado busca una empresa asociada
                    if (operacion == "") {
                        $.each(operaciones.data, function(clave, value) {
                            if (value.id == id) {
                                operacion = value.catering_id;
                             
                            }
                        })
                    }
                    return operacion;
                }


                 const result_apropiacion = (id) => {
                    var operacion = "";
                    var apropiacion = "";
                    // Busca primero al empleado en sectores que es mas pequeño
                    Object.keys(sectores.data).forEach((sector)=>{
                        // console.log(sectores.data[sector].id_empresa +"=="+id);
                        if (sectores.data[sector].id_empresa == id) {
                          
                            apropiacion = sectores.data[sector].id_empresa;

                        }
                    })
                    // Si no encontro un sector asociado busca una empresa asociada
                    if (operacion == "") {
                        $.each(operaciones.data, function(clave, value) {
                            if (value.id == id) {
                             
                                // apropiacion = value.centroapropiacion_id
                                apropiacion = value.id
                            }
                        })
                    }
                    return apropiacion;
                }

                const result_zonal = (id) => {
                    var zonales = "";
                    $.each(zonal.data, function(clave, value) {
                        if (clave == id) {
                            zonales = value.nombre;
                        }
                    })
                    return zonales;
                }


                 const result_manipulador = (dni) => {
                    var manip = "";
                    $.each(manipulacion.data, function(clave, value) {
                        if (clave == dni) {
                            manip = value.link;
                        }
                    })
                    return manip;
                }


                   const result_manipulador_vto = (dni) => {
                    var manip = "";
                    $.each(manipulacion.data, function(clave, value) {
                        if (clave == dni) {
                            manip = value.vto;
                        }
                    })
                    return manip;
                }

                const result_vacunacion = (legajo) => {
                    var vacu = "";
                    $.each(vacunacion.data, function(clave, value) {
                        if (clave == legajo) {
                            vacu = value.certificado;
                        }
                    })
                    return vacu;
                }

                const result_dosis = (dni) => {
                    var dos = "";
                    $.each(dosis.data, function(clave, value) {
                        if (clave == dni) {
                            // console.log("datos obtenidos!")
                           dos = Object.keys(value).length/2
                        }
                    })
                    return dos;
                }


                const result_avatar = (dni) => {
                    var avtr = "";
                    $.each(avatar.data, function(clave, value) {
                        if (clave == dni) {
                            avtr = value.imagen;
                        }
                    })
                    return avtr;
                }

                const reemplazarIndefinido = (string) => {
                    if (string==undefined || string == null || string == '') {
                        return "-"
                    }
                    return string
                }

                const reemplazarIndefinidoDosis = (string) => {
                    if (string==undefined || string == null || string == '') {
                        return "0"
                    }
                    return string
                }

                var contable_id="";
                var contable_desc="";
               let pesos = Intl.NumberFormat('es-ES');
                $.each(personal.data, function(clave, valor) {

                    console.log(valor)

                     observacion = (observaciones.data[valor.leg_numdoc] != undefined) ? true : false;
                     
                    if(legales.data[valor.leg_numdoc] != null) return true;
                    
                    if (valor.leg_fecegr == null) {
                         let distribucion = result_distribucion(valor.leg_numero);
                        

                         console.log("distribucion")
                         console.log(distribucion.operacion)
                        
                      if (distribucion.operacion=="029081515"||distribucion.operacion=="033503144"||distribucion.operacion=="033503155"||distribucion.operacion=="033503166"||distribucion.operacion=="033503177"||distribucion.operacion=="67"||distribucion.operacion=="68"||distribucion.operacion=="69"||distribucion.operacion=="70"||distribucion.operacion=="71"||distribucion.operacion=="75"||distribucion.operacion=="999992"||distribucion.operacion=="999993"||distribucion.operacion=="999999") {
                            contable_id=1;
                            contable_desc="Producción";
                        }else if (distribucion.operacion=="0335029"||distribucion.operacion=="1001") {
                            contable_id=2;
                            contable_desc="Comercialización";
                        }else if (distribucion.operacion=="000558"||distribucion.operacion=="000553"||distribucion.operacion=="000555"||distribucion.operacion=="001016"||distribucion.operacion=="010101999"||distribucion.operacion=="0335030"||distribucion.operacion=="0335031"||distribucion.operacion=="033503188"||distribucion.operacion=="033503199"||distribucion.operacion=="033503200"||distribucion.operacion=="033503201"||distribucion.operacion=="095447887"||distribucion.operacion=="100111928992"||distribucion.operacion=="1002"||distribucion.operacion=="1003"||distribucion.operacion=="1004"||distribucion.operacion=="1005"||distribucion.operacion=="1006"||distribucion.operacion=="1007"||distribucion.operacion=="1008"||distribucion.operacion=="1009"||distribucion.operacion=="1010"||distribucion.operacion=="1011"||distribucion.operacion=="1012"||distribucion.operacion=="1014"||distribucion.operacion=="67"||distribucion.operacion=="70"||distribucion.operacion=="71"||distribucion.operacion=="75") {
                            contable_id=4;
                            contable_desc="Administración";
                        }else{
                            contable_id=3;
                            contable_desc="Operación";
                        }
                       
                        lista.push({
                            "cod": reemplazarIndefinido(result_apropiacion(distribucion.operacion)),
                            "salario": pesos.format(valor.leg_basico),
                            "razonsocial": "Food Service America",
                            "bejerman": reemplazarIndefinido(result_operacion_bejerman(distribucion.operacion)),
                            "legajo": reemplazarIndefinido(valor.leg_numero),
                            "nombre": reemplazarIndefinido(valor.leg_nombre),
                            "apellido": reemplazarIndefinido(valor.leg_apellido),
                            "ingreso": reemplazarIndefinido(valor.leg_fecing.date.replace(" 00:00:00.000000", "")),
                            "ingreso_reco": reemplazarIndefinido(valor.leg_fecingr.date.replace(" 00:00:00.000000", "")),
                            "categoria": reemplazarIndefinido(result_categoria(valor.cat_codigo)),
                            "categoria_id": reemplazarIndefinido(valor.cat_codigo),
                            "operacion": reemplazarIndefinido(result_operacion(distribucion.operacion)),
                            "operacion_id": reemplazarIndefinido(distribucion.operacion),
                            "madre": reemplazarIndefinido(result_madre(distribucion.operacion)),
                            "tipo": reemplazarIndefinido(result_tipo(distribucion.operacion)),
                            "idDistribucion": distribucion.idDistribucion,
                            "zonal": reemplazarIndefinido(result_zonal(result_distribucion(valor.leg_numero).operacion)),
                            "dni": valor.leg_numdoc,
                            "cuil": valor.leg_cuil,
                            "estadocivil": valor.est_descrip,
                            "convencionado": valor.leg_convencionado,
                            "seccion": valor.sec_descrip,
                            "seccion_id": valor.sec_codigo,
                            "sin_codigo": valor.sin_codigo,
                            "sin_descrip": valor.sin_descrip,
                            "oso_codigo": valor.oso_codigo,
                            "oso_descrip": valor.oso_descrip,
                            "mpr_codigo": valor.mpr_codigo,
                            "mpr_descrip": valor.mpr_descrip,
                            "nacionalidad":valor.nacionalidad,
                            "contable_id": contable_id,
                            "contable_descri": contable_desc,


                            "domicilio": valor.leg_domic+" "+valor.leg_puerta,
                            "localidad":valor.leg_locali,
                            "depto":valor.leg_depto,
                            "entrecalles":valor.leg_entrecalles,
                            "telefono":valor.leg_telefono,
                            "email":valor.leg_mail,

                            "sexo": valor.leg_sexo,
                            "nacimiento": valor.leg_fecnac.date.replace(" 00:00:00.000000",""),
                            "cbu": valor.cbu,
                            "observacion":observacion,
                            "manipulador":reemplazarIndefinido(result_manipulador(valor.leg_numdoc)),
                            "manipulador_vto":reemplazarIndefinido(result_manipulador_vto(valor.leg_numdoc)),
                            "vacunado":reemplazarIndefinido(result_vacunacion(valor.leg_numero)),
                            "dosis":reemplazarIndefinidoDosis(result_dosis(valor.leg_numdoc)),
                            "avatar": reemplazarIndefinido(result_avatar(valor.leg_numdoc)),
                        })
                    }
                });

                $.each(personal_cat.data, function(clave, valor) {

                     observacion = (observaciones.data[valor.leg_numdoc] != undefined) ? true : false;
                    if(legales.data[valor.leg_numdoc] != null) return true;

                    if (valor.leg_fecegr == null) {
                        let distribucion = result_distribucion(valor.leg_numero);

                       
                        
                     if (distribucion.operacion=="029081515"||distribucion.operacion=="033503144"||distribucion.operacion=="033503155"||distribucion.operacion=="033503166"||distribucion.operacion=="033503177"||distribucion.operacion=="67"||distribucion.operacion=="68"||distribucion.operacion=="69"||distribucion.operacion=="70"||distribucion.operacion=="71"||distribucion.operacion=="75"||distribucion.operacion=="999992"||distribucion.operacion=="999993"||distribucion.operacion=="999999") {
                            contable_id=1;
                            contable_desc="Producción";
                        }else if (distribucion.operacion=="0335029"||distribucion.operacion=="1001") {
                            contable_id=2;
                            contable_desc="Comercialización";
                        }else if (distribucion.operacion=="000558"||distribucion.operacion=="000553"||distribucion.operacion=="000555"||distribucion.operacion=="001016"||distribucion.operacion=="010101999"||distribucion.operacion=="0335030"||distribucion.operacion=="0335031"||distribucion.operacion=="033503188"||distribucion.operacion=="033503199"||distribucion.operacion=="033503200"||distribucion.operacion=="033503201"||distribucion.operacion=="095447887"||distribucion.operacion=="100111928992"||distribucion.operacion=="1002"||distribucion.operacion=="1003"||distribucion.operacion=="1004"||distribucion.operacion=="1005"||distribucion.operacion=="1006"||distribucion.operacion=="1007"||distribucion.operacion=="1008"||distribucion.operacion=="1009"||distribucion.operacion=="1010"||distribucion.operacion=="1011"||distribucion.operacion=="1012"||distribucion.operacion=="1014"||distribucion.operacion=="67"||distribucion.operacion=="70"||distribucion.operacion=="71"||distribucion.operacion=="75") {
                            contable_id=4;
                            contable_desc="Administración";
                        }else{
                            contable_id=3;
                            contable_desc="Operación";
                        }

                        lista.push({
                            "cod": reemplazarIndefinido(result_apropiacion(distribucion.operacion)),
                            "salario": pesos.format(valor.leg_basico),
                            "razonsocial": "Food Service Catering",
                            "bejerman": reemplazarIndefinido(result_operacion_bejerman(distribucion.operacion)),
                            "legajo": reemplazarIndefinido(valor.leg_numero),
                            "nombre": reemplazarIndefinido(valor.leg_nombre),
                            "apellido": reemplazarIndefinido(valor.leg_apellido),
                            "ingreso": reemplazarIndefinido(valor.leg_fecing.date.replace(" 00:00:00.000000", "")),
                            "ingreso_reco": reemplazarIndefinido(valor.leg_fecingr.date.replace(" 00:00:00.000000", "")),
                            "categoria": reemplazarIndefinido(result_categoria(valor.cat_codigo)),
                            "categoria_id": reemplazarIndefinido(valor.cat_codigo),
                            "operacion": reemplazarIndefinido(result_operacion(distribucion.operacion)),
                            "operacion_id": reemplazarIndefinido(distribucion.operacion),
                            "madre": reemplazarIndefinido(result_madre(distribucion.operacion)),
                            "tipo": reemplazarIndefinido(result_tipo(distribucion.operacion)),
                            "idDistribucion": distribucion.idDistribucion,
                            "zonal": reemplazarIndefinido(result_zonal(result_distribucion(valor.leg_numero).operacion)),
                            "dni": valor.leg_numdoc,
                            "cuil": valor.leg_cuil,
                            "estadocivil": valor.est_descrip,
                            "convencionado": valor.leg_convencionado,
                            "seccion": valor.sec_descrip,
                            "seccion_id": valor.sec_codigo,
                            "sin_codigo": valor.sin_codigo,
                            "sin_descrip": valor.sin_descrip,
                            "oso_codigo": valor.oso_codigo,
                            "oso_descrip": valor.oso_descrip,
                            "mpr_codigo": valor.mpr_codigo,
                            "mpr_descrip": valor.mpr_descrip,
                            "nacionalidad":valor.nacionalidad,
                            "contable_id": contable_id,
                            "contable_descri": contable_desc,

                            "sexo": valor.leg_sexo,
                            "nacimiento": valor.leg_fecnac.date.replace(" 00:00:00.000000",""),
                            "cbu": valor.cbu,
                            "observacion":observacion,
                            "manipulador":reemplazarIndefinido(result_manipulador(valor.leg_numdoc)),
                            "manipulador_vto":reemplazarIndefinido(result_manipulador_vto(valor.leg_numdoc)),
                            "vacunado":reemplazarIndefinido(result_vacunacion(valor.leg_numero)),
                            "dosis":reemplazarIndefinidoDosis(result_dosis(valor.leg_numdoc)),
                            "avatar": reemplazarIndefinido(result_avatar(valor.leg_numdoc)),
                        })
                    }
                });



               

                // console.log(lista);
                this.personal = lista;
                this.triggers.load = false;
                })).then(() => {
                    $('#empresas').DataTable({
                        "language": {
                            "decimal": ",",
                            "thousands": ".",
                            "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                            "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                            "infoPostFix": "",
                            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
                            "loadingRecords": "Cargando...",
                            "lengthMenu": "Mostrar _MENU_ registros",
                            "paginate": {
                                "first": "Primero",
                                "last": "Último",
                                "next": "Siguiente",
                                "previous": "Anterior"
                            },
                            "processing": "Procesando...",
                            "search": "Buscar:",
                            "searchPlaceholder": "Término de búsqueda",
                            "zeroRecords": "No se encontraron resultados",
                            "emptyTable": "Ningún dato disponible en esta tabla",
                            "aria": {
                                "sortAscending":  ": Activar para ordenar la columna de manera ascendente",
                                "sortDescending": ": Activar para ordenar la columna de manera descendente"
                            },
                        },
                        "scrollX": false, 
                        "search": {regex:true},
                        dom: 'Bfrtip',
                        "order": [
                            [0, "asc"]
                        ],
                        responsive: false,
                        nowrap: false,
                        buttons: [
                            'copy',
                            'excel',
                            'csv',
                            'pdf'
                        ]
                    });
                });

                
        },
        refrescarPersonal(seReasigno){
            this.modalAdministrar.open = false;
            this.modalLegales.open = false;
            this.modalObservar.open = false;
            this.modalVacunas.open = false;
            
            if (seReasigno) {
                $('#empresas').dataTable().fnDestroy();
                this.obtenerPersonal();
            }

             
        },

         getLegajo(legajo){
               
$('#novedades_legajo').dataTable().fnDestroy();
 //$("body").css("overflow","hidden");

 $("#info_legajo").slideDown();


  $("#cerrar_legajo").click(function() {
  
                         $("#info_legajo").slideUp();
                          //$("body").css("overflow","scroll");
  
  
                      })

  this.novedades = [];
            this.triggers.load = true;

            axios.get("https://auth.foodservice.com.ar/index_test.php?type=novedades_legajo&legajo="+legajo+"&access_token=1234567")
                .then((response) => {
                    var vue = this;
                    var obs = 0;




                    $.each(response.data, (id, item) => {
                        let caso = item;

                        var certif_u = '-';
                        if (caso != null) {

                            if (caso.observacion != undefined) {

                                obs = 1;
                            } else {
                                obs = 0;
                            }




                            if (caso.certificado_update != undefined) {

                                $.each(caso.certificado_update, function(clab, itb) {

                                    certif_u = itb.certificado;



                                });

                                cert_c = certif_u;
                            }




                            const reemplazarIndefinido = (string) => {
                                if (string == undefined || string == null || string == '') {
                                    return "-"
                                }
                                return string
                            }



                            var fechaInicio = new Date(caso.desde).getTime();
                            var fechaFin = new Date(caso.hasta).getTime();

                            var diff = fechaFin - fechaInicio;
                            var bejerman = "-";
                            if (caso.bejerman_id) {
                                bejerman = caso.bejerman_id;
                            }




                            vue.novedades.push({
                                fireId: id,
                                desde: caso.desde,
                                destino: caso.operacion,
                                detalle: caso.detalle,
                                fecha: caso.fecha,
                                fecha_novedad: caso.fecha_novedad,
                                hasta: caso.hasta,
                                certificado: caso.certificado,
                                legajo: caso.legajo,
                                nombre: caso.nombre,
                                novedad: caso.novedad,
                                horas: reemplazarIndefinido(caso.horas),
                                noti: reemplazarIndefinido(caso.obs),
                                bejerman_id: bejerman,
                                operacion_raiz: caso.operacion_raiz,
                                responsable: caso.responsable,
                                responsable_dni: caso.responsable_dni,
                                status: reemplazarIndefinido(caso.status),
                                observacion: obs,
                                statusMensaje: caso.status,
                                dias: (diff / (1000 * 60 * 60 * 24)) + 1
                            });




                        }
                    });



                })
                .then(() => {

                  this.triggers.load = false;
                  this.$nextTick(()=>{
                 
   
                        
  
                      $('#novedades_legajo').DataTable({
                          "language": {
                              "decimal": ",",
                              "thousands": ".",
                              "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                              "infoEmpty": "Mostrando registros del0 al0 de un total de0 registros",
                              "infoPostFix": "",
                              "infoFiltered": "(filtrado de un total de _MAX_ registros)",
                              "loadingRecords": "Cargando...",
                              "lengthMenu": "Mostrar _MENU_ registros",
                              "paginate": {
                                  "first": "Primero",
                                  "last": "Último",
                                  "next": "Siguiente",
                                  "previous": "Anterior"
                              },
                              "processing": "Procesando...",
                              "search": "Buscar:",
                              "searchPlaceholder": "Término de búsqueda",
                              "zeroRecords": "No se encontraron resultados",
                              "emptyTable": "Ningún dato disponible en esta tabla",
                              "aria": {
                                  "sortAscending": ": Activar para ordenar la columna de manera ascendente",
                                  "sortDescending": ": Activar para ordenar la columna de manera descendente"
                              },
                          },
                          "scrollX": false,
                          "search": {
                              regex: true
                          },
                          dom: 'Bfrtip',
                          responsive: false,
                          nowrap: false,
                          "initComplete": function(settings, json) {
  
                              setTimeout(function() {
                                  console.log("completo");
                                  $('#novedades_legajo').DataTable().order([7, 'desc']).draw();
                              }, 2000)
  
  
                              // call your function here
                          },
                          buttons: [
  
                              'excel',
                              'csv',
  
                              {
                                  text: 'En espera',
                                  className: 'btn btn-warning',
                                  action: function(e, dt, node, config) {
                                      var table = $('#novedades').DataTable();
                                      table.columns(0).search("En espera").draw();
                                      table.columns(16).search("").draw();
                                  }
                              },
                              {
                                  text: 'Observado',
                                  className: 'btn btn-warning',
                                  action: function(e, dt, node, config) {
                                      var table = $('#novedades').DataTable();
                                      table.columns(0).search("Observado").draw();
                                      table.columns(16).search("").draw();
                                  }
                              },
                              {
                                  text: 'Rechazado',
                                  className: 'btn btn-warning',
                                  action: function(e, dt, node, config) {
                                      var table = $('#novedades').DataTable();
                                      table.columns(0).search("Rechazado").draw();
                                      table.columns(16).search("").draw();
                                  }
                              }, {
                                  text: 'Resuelto',
                                  className: 'btn btn-warning',
                                  action: function(e, dt, node, config) {
                                      var table = $('#novedades').DataTable();
                                      table.columns(0).search("Resuelto").draw();
                                      table.columns(16).search("").draw();
                                  }
                              }, {
                                  text: 'Sin responder',
                                  className: 'btn btn-danger',
                                  action: function(e, dt, node, config) {
                                      var table = $('#novedades').DataTable();
                                      table.columns(16).search("Sin responder").draw();
                                  }
                              },
                              {
                                  text: 'Todos',
                                  className: 'btn btn-warning',
                                  action: function(e, dt, node, config) {
                                      var table = $('#novedades').DataTable();
                                      table.columns(0).search("").draw();
                                      table.columns(6).search("").draw();
                                      table.columns(16).search("").draw();
                                  }
                              }
                          ]
                      })
  
  
  
                      const diasEntreFechas = (desde, hasta) => {
  
                          var dia_actual = desde;
                          var fechas = [];
                          while (dia_actual.isSameOrBefore(hasta)) {
                              fechas.push(dia_actual.format('YYYY-MM-DD'));
                              dia_actual.add(1, 'days');
                          }
                          return fechas;
                      };
  
                      $("#buscar_legajo").click(function() {
  
                          console.log($("#desde").val() + "hasta " + $("#hasta").val())
                          var filtro = "";
                          var desde = moment($("#desde").val());
                          var hasta = moment($("#hasta").val());
                          var results = diasEntreFechas(desde, hasta);
  
  
                          $.each(results, function(key, value) {
                              filtro += value + "|";
                          })
                          var table = $('#novedades_legajo').DataTable();
                          console.log(filtro.substring(0, filtro.length - 1));
                          table.column(10).search(filtro.substring(0, filtro.length - 1), true, false).draw();
  
  
                      })

                  })


                })

        

                      






        }
    },
    mounted() {
        this.obtenerPersonal();
    },
};
</script>

<style scoped>
.fila-no-distribuido {
    /* color: red; */
    /* text-shadow: rgba(255, 0, 0, 0.603) 0px 0px 1px; */
    background: radial-gradient(#ff00001a, transparent 91%);
}
.observacion-existente {
    background: #10b759;
    border-radius: 50%;
    padding: 3px 4px;
    padding-top: 4px;
    width: 16px;
    height: 16px;
    position: absolute;
    transform: translate(60px, -20px);
}


#info_legajo {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    z-index: 9999999;
    padding: 40px;
    left: 0;
    top: 0;
    display: none;
}

#info_legajo td{
    border: 1px solid #ccc;
}

#legajo_foto{
    width: 300px;
    height: 300px;
    background-color: #ccc;
}

.circulo {
    width: 100px;
    height: 100px;
    background: #b7b5b5;
    border-radius: 50%;
}

#novedades_legajo td{
    font-size: 10px;
}

#header_legajo{
    width: 100%;
    background-color: #7e86ed;
    height: 37px;
    position: fixed;
    left: 0;
    top: 0;
}
</style>