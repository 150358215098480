<template>
  <list 
      :data="data"
      title="Reporte del curso:"
      :pageLength="10"
      :titleBadge="course.title"
      :columns="[
          {
              label: '',
              trackBy: 'state',
              type: 'dot'
          },
          {
              label: 'Nombre',
              trackBy: 'zonal_name',
              type: 'text'
          },
          {
              label: 'Dotacion',
              trackBy: 'crew_count',
              type: 'badge',
          },...getModulesAsColumns()             
      ]"
      @show-exams="showCrewDetail($event)"
  >
  </list>

  <transition name="modal" v-if="trigger.crewDetail">
    <div class="modal-mask" style="cursor: default;">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <div name="body">
              <div class="card">
                <div class="card-body text-left">
                  <List
                    v-if="trigger.crewDetailList"
                    :data="crewDetail"
                    title="Personal capacitado"
                    :pageLength="5"
                    :closeAction="true"
                    :columns="[
                        {
                            label: 'Legajo',
                            trackBy: 'legajo',
                            type: 'text'
                        },
                        {
                            label: 'Nombre',
                            trackBy: 'nombre',
                            type: 'text'
                        },
                        {
                            label: 'Operacion',
                            trackBy: 'operation',
                            type: 'text'
                        },
                        {
                            label: 'Nota',
                            type: 'function',
                            formatter: getLastAttemptScore
                        },
                        {
                            label: 'Fecha',
                            type: 'function',
                            formatter: getLastAttemptDate
                        }
                    ]"
                    :actions="[
                      {
                        color:'primary',
                        emitter:'employee-attemp',
                        label:'Ver'
                      }
                    ]"
                    @employee-attemp="showAttempDetail($event)"
                    @close="showCrewDetail()"
                  />

                  <div v-if="trigger.crewDetailAttemp">
                    
                    <div class="d-flex align-items-center justify-content-between mb-3">
                      <h5 class="mb-0">Examen: <div class="badge badge-primary">{{capitalizeEacheR(employeeAttemp.nombre)}}</div></h5>
                      <button class="btn btn-light" @click="showAttempDetail()">&larr; Volver</button>
                    </div>
                    <!-- <pre>{{employeeAttemp}}</pre> -->
                    <div class="bg-light p-3 rounded mb-2 d-flex align-items-center justify-content-between">
                      <div>
                        <h6>
                          {{course.title}}
                        </h6>
                        <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                        &nbsp;
                        <div class="badge bg-white">
                          {{dateFormateR(employeeAttemp.last_attemp.timestamp)}}
                        </div>
                        &nbsp;
                        Nota:
                        <div class="badge bg-white">
                          {{employeeAttemp.last_attemp.resultado}}
                        </div>
                      </div>
                      <div>
                          <div class="btn btn-xs btn-primary mr-auto" @click="getPDF(course.title,employeeAttemp.nombre)">
                            <!-- <svg width="14p" height="14" fill="currentColor" viewBox="0 0 384 512" style="vertical-align: bottom;"><path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM64 224H88c30.9 0 56 25.1 56 56s-25.1 56-56 56H80v32c0 8.8-7.2 16-16 16s-16-7.2-16-16V320 240c0-8.8 7.2-16 16-16zm24 80c13.3 0 24-10.7 24-24s-10.7-24-24-24H80v48h8zm72-64c0-8.8 7.2-16 16-16h24c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H176c-8.8 0-16-7.2-16-16V240zm32 112h8c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16h-8v96zm96-128h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H304v32h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H304v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V304 240c0-8.8 7.2-16 16-16z"></path></svg> -->
                            <svg viewBox="0 0 384 512" width="14p" height="14" fill="currentColor" style="vertical-align: bottom;"><path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM216 232V334.1l31-31c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-72 72c-9.4 9.4-24.6 9.4-33.9 0l-72-72c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l31 31V232c0-13.3 10.7-24 24-24s24 10.7 24 24z"/></svg>
                            PDF
                          </div>
                      </div>
                    </div>

                    <div id="data-content">
                      <div v-for="exam,e,i in employeeAttemp.last_attemp.data" :key="e">
                        <div class="border border-light p-2" style="margin-top:-1px">
                          <b>{{i+1}}. {{exam.statement}} </b>
                          <div v-for="answer,a in exam.answers" :key="a">
                            <b v-if="answer.checked">
                              {{(answer.checked)?'&#x2611;':'&#9744;'}} {{answer.label}} ({{(answer.correct)?'Correcta':'Incorrecta'}})
                            </b>
                            <span v-else>
                              {{(answer.checked)?'&#x2611;':'&#9744;'}} {{answer.label}} ({{(answer.correct)?'Correcta':'Incorrecta'}})
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <table class="table d-none" id="tablee">
                        <tbody>
                            <tr>
                                <th>
                                    Nombre: {{capitalizeEacheR(employeeAttemp.nombre)}} |

                                    Capacitacion: {{capitalizeEacheR(course.title)}} <br><br>

                                    Fecha: {{dateFormateR(employeeAttemp.last_attemp.timestamp)}} |

                                    Nota: {{employeeAttemp.last_attemp.resultado}} |

                                    Resultado: {{(employeeAttemp.last_attemp.resultado >= 70) ? "APROBADO" : "DESAPROBADO"}}
                                </th>
                            </tr>
                            <tr>
                                <td><br>Detalle del exámen<br></td>
                            </tr>
                            <tr v-for="exam,e,i in employeeAttemp.last_attemp.data" :key="e">
                                <th scope="row">
                                    <br>
                                    {{i+1}}. {{exam.statement}}
                                    <br>
                                    <template v-for="answer,a in exam.answers" :key="a">
                                        <br>
                                        <b border="1">
                                            ({{(answer.correct)?'Correcta':'Incorrecta'}}) {{answer.label}} {{(answer.checked)?'[Respuesta seleccionada]':''}}
                                        </b>
                                        <br>
                                    </template>
                                </th>
                                
                            </tr>
                        </tbody>
                    </table>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// import axios from 'axios';
import {dateFormat,capitalizeEach} from '@/helpers.js'
import List from '@/components/List'
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
export default {
    name: 'TrainedByCourse',
    components: { List, }, 
    props: {
      distribution:{
        type: [Object,Array]
      },
      course: Object,
      trained: Object,
    },
    data() {
      return {
        data:null,
        columns:null,
        actions:null,
        trigger:{
          list:false,
          crewDetail:false,
          crewDetailList:false,
          crewDetailAttemp:false
        },
        crewDetail:null,
        employeeAttemp:null
      }
    },
    methods:{
      getPDF(modulo,empleado){
          const doc = new jsPDF()
          autoTable(doc, { html: '#tablee' })
          let year = new Date().getFullYear();
          let m = modulo.toLocaleLowerCase().replaceAll(' ','_').replaceAll(".","");
          let e = empleado.toLocaleLowerCase().replaceAll(' ','_');
          doc.save(`${m}_${e}_${year}.pdf`)
      },
      getModulesAsColumns(){
        return Object.keys(this.course.modules).map((m,i)=>{
            // let modulo = this.course.modules[m];

            return {
                label: 'Mod-'+(i+1),
                trackBy: m,
                type: 'actionBadge',
                actionBadgeEmitter:'show-exams',
                conditionColors:[
                    {color:'success',condition:'equal',amount:100},
                    {color:'danger',condition:'less',amount:100}
                ],
                format:'percentage',
                percentageTotal:'crew_count'
            }
        });
      },
      showCrewDetail(row){
        if(!this.trigger.crewDetail){
          this.crewDetail = row.data.crew_data[row.id];
          this.trigger.crewDetail = true;
          this.trigger.crewDetailList = true;
        } else {
          this.crewDetail = null;
          this.trigger.crewDetail = false;
          this.trigger.crewDetailList = false;
        }
      },
      getLastAttemptScore(d){
        if(d==undefined || d.intentos==null) return 'Sin cursar'
        return d.intentos[Object.keys(d.intentos).at(-1)].resultado
      },
      getLastAttemptDate(d){
        if(d==undefined || d.intentos==null) return 'Sin cursar'
        return dateFormat(d.intentos[Object.keys(d.intentos).at(-1)].timestamp)
      },
      showAttempDetail(data){
        if(!this.trigger.crewDetailAttemp){
          this.employeeAttemp = data
          this.employeeAttemp['last_attemp'] = data.intentos[Object.keys(data.intentos).at(-1)]

          this.trigger.crewDetailList = false;
          this.trigger.crewDetailAttemp = true;
        } else {
          this.employeeAttemp = null
          this.trigger.crewDetailList = true;
          this.trigger.crewDetailAttemp = false;
        }
      },
      capitalizeEacheR(d){return capitalizeEach(d)},
      dateFormateR(d){return dateFormat(d)},
    },
    mounted() {
      console.log('hola estoy en trained by course');
      // Se itera a traves de los zonales

      console.log('distri',this.distribution);
      console.log('trained',this.trained);

      Object.keys(this.distribution).forEach((d)=>{
        let row = {};

        row['zonal_name'] = this.distribution[d].name;
        row['crew_count'] = this.distribution[d].dotacion_count;
        row['crew_data'] = {};
        row['state'] = 'success';

        Object.keys(this.course.modules).forEach((m)=>{ row[m] = 0; });

        // Se itera sobre las operaciones del zonal
        Object.keys(this.distribution[d].operations).forEach((o)=>{
          // console.log('   ',this.distribution[d].operations[o].operation_name);
  
          
          // Si tiene personal en la operacion
          if(this.distribution[d].operations[o].personal != undefined){
            // Se itera sobre el personal de la operacion
            Object.keys(this.distribution[d].operations[o].personal).forEach((p)=>{
              // TODO  Saltear si es p es el zonal
              // console.log('persona',this.distribution[d].operations[o].personal[p]);
              // if(this.distribution[d].operations[o].personal[p].legajo == this.distribution[d].legajo){
              //   // 
              //   return
              // }
              // console.log('     · ',this.distribution[d].operations[o].personal[p].nombre);
              let employee = this.distribution[d].operations[o].personal[p];
             
              Object.keys(this.course.modules).forEach((m)=>{
                if(row['crew_data'][m]==undefined) row['crew_data'][m] = [];

                let last_employee_added = row['crew_data'][m].length

                row['crew_data'][m].push({
                  ...{
                    module_id:m,
                    course_id:this.course.course_id,
                    nombre:employee.nombre,
                    legajo:employee.legajo
                  },
                  ...{
                    operation:this.distribution[d].operations[o].operation_name
                  }
                });

                /**
                 * ATENCION: LA condicion se amplio debido a que rrhh duplico al empleado con legajo 11353 y la ultima capacitacion la hizo con el legajo 378
                 * Se añadio la condicion: "|| employee.legajo==11353 && this.trained[378] != undefined" para suplir ese inconveniente.
                 * 
                 */
                if (this.trained!=null && (this.trained[employee.legajo] != undefined || employee.legajo==11353 && this.trained[378] != undefined)){
                  // find current module in trained
                  if(employee.legajo==11353) employee.legajo=378
                  
                  let module_trained = Object.keys(this.trained[employee.legajo]).find(((t)=>this.trained[employee.legajo][t].modulo_id == m))
                  
                  if (module_trained!=undefined) {
                    // Save the capacitacion using the key
                    module_trained = this.trained[employee.legajo][module_trained];
                    // Add the row

                    let score = 0;
                    if(module_trained.intentos != undefined){
                      // Get last try score
                      score = module_trained.intentos[Object.keys(module_trained.intentos).at(-1)].resultado;
                    }

                    if(score>=70){
                      row['crew_data'][m][last_employee_added].intentos = module_trained.intentos;
                      row['crew_data'][m][last_employee_added].score = score;
                      row[m]++;
                    }
                  }
                }
              });





              // if (this.trained!=null && this.trained[employee.legajo] != undefined) {
              //   Object.keys(this.trained[employee.legajo]).forEach(((e)=>{











              //     if(this.course.modules[this.trained[employee.legajo][e].modulo_id] != undefined){
              //       // TODO: Check if module has more than one exam
              //       // this.course.modules[this.trained[employee.legajo][e].modulo_id].exams
              //       let score = 0;
              //       if(this.trained[employee.legajo][e].intentos != undefined){
              //         // Get last try score
              //         score = this.trained[employee.legajo][e].intentos[Object.keys(this.trained[employee.legajo][e].intentos).at(-1)].resultado;
              //       }
              //       if(score>=70){
              //         // if (row[this.trained[employee.legajo][e].modulo_id] == undefined) {
              //         //   row[this.trained[employee.legajo][e].modulo_id] = 1;
              //         // }else {
              //         //   }
              //         // console.log(employee.legajo,'esta en',this.distribution[d].operations[o]);

              //         if(row['crew_data'][this.trained[employee.legajo][e].modulo_id]==undefined){
              //           row['crew_data'][this.trained[employee.legajo][e].modulo_id] = [];
              //         }
              //         // console.log({...this.trained[employee.legajo][e],...{operation:this.distribution[d].operations[o].operation_name}});
              //         row['crew_data'][this.trained[employee.legajo][e].modulo_id].push({...this.trained[employee.legajo][e],...{operation:this.distribution[d].operations[o].operation_name}});
              //         row[this.trained[employee.legajo][e].modulo_id]++;
              //       } else {
              //         if(row['crew_data'][this.trained[employee.legajo][e].modulo_id]==undefined){
              //           row['crew_data'][this.trained[employee.legajo][e].modulo_id] = [];
              //         }
              //         // console.log({...this.trained[employee.legajo][e],...{operation:this.distribution[d].operations[o].operation_name}});
              //         row['crew_data'][this.trained[employee.legajo][e].modulo_id].push({...this.trained[employee.legajo][e],...{operation:this.distribution[d].operations[o].operation_name}});
              //           // row[this.trained[employee.legajo][e].modulo_id]++;
              //       }
              //     } else {
              //       // console.log(employee.nombre, 'desaparecido');

              //       if(row['crew_data'][this.trained[employee.legajo][e].modulo_id]==undefined){
              //         row['crew_data'][this.trained[employee.legajo][e].modulo_id] = [];
              //       }

              //       Object.keys(this.course.modules).forEach((m)=>{
              //         if(row['crew_data'][m]==undefined) row['crew_data'][m] = [];

              //         row['crew_data'][m].push({
              //           module_id: m,
              //           legajo:employee.legajo,
              //           nombre:employee.nombre,
              //           operation:this.distribution[d].operations[o].operation_name
              //         })
              //         // row[m]++;
                      
              //       })
              //     }
  
              //   }))
              // } else {
              //   Object.keys(this.course.modules).forEach((m)=>{
              //     if(row['crew_data'][m]==undefined) row['crew_data'][m] = [];

              //     row['crew_data'][m].push({
              //       module_id: m,
              //       legajo:employee.legajo,
              //       nombre:employee.nombre,
              //       operation:this.distribution[d].operations[o].operation_name
              //     })
              //     // row[m]++;
                  
              //   })
              // }

            })
          }
        });

        // Se recorren los modulos cuantificados para promediar y sacar un valor de todos
        let average = 0;
        let n = 0;
        Object.keys(this.course.modules).forEach((m,i)=>{
          average += row[m];
          n++;
        });

        // row['state'] = average / total;

        if(average<100) {
          row['state'] = 'warning';

          if (average<90) {
            row['state'] = 'danger';
          }
        }
        if(this.data==null) this.data = [];
        this.data.push(row);

      });
    },
}
</script>

<style scoped>
#data-content {
  max-height: 500px;
  overflow-y: scroll;
}
</style>
